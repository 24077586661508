import { Injectable } from '@angular/core';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';

@Injectable()
export class CustomerService{
  private ngxApiResources;
  constructor(private _ngxApiResources: NgxApiResources) {
    this.ngxApiResources = this._ngxApiResources.loadResources;
  }

  listCustomer(){
    let promise = this.ngxApiResources.customerResource._listCustomer().$promise;
    return promise;
  }
}