import { Component, OnInit,Input,Output,EventEmitter, OnChanges } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ITypeWastePayload } from 'src/app/services/payload/type-waste.payload';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';
import { IAction, SubmitAction } from 'src/app/utils/utils';
import { IResponse } from 'src/app/api/models/response.interface';

@Component({
  selector: 'sesa-add-type-waste',
  templateUrl: './add-type-waste.component.html',
  styleUrls: ['./add-type-waste.component.scss']
})
export class AddTypeWasteComponent implements OnInit, OnChanges {

  formGrp: FormGroup;

  @Input()  typeWaste: TypeWaste;
  @Output() typeWasteChange   = new EventEmitter<TypeWaste>();
  @Output() createEntityEvent = new EventEmitter<ITypeWastePayload>();
  @Output() updateEntityEvent = new EventEmitter<ITypeWastePayload>();
  @Input()  succes: IResponse ;

  urlPattern: "/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i";
  
  @Input() action: IAction = {lib: "Register",event: SubmitAction.ADDING};
  @Output() actionChange = new EventEmitter<IAction>();

  constructor() { 
    
  }

  ngOnInit() {
    this._iniForm();
  }

  ngOnChanges() {
    console.log(this.typeWaste)
    if(this.succes && !this.typeWaste){
      if(!this.succes.error){
        this.onReset()
      } 
    }
 
    if(this.typeWaste) {
      this.formGrp.patchValue({
        "lib": this.typeWaste.lib,
        "description": this.typeWaste.description,
        "uvp": this.typeWaste.uvp,
        "uvm": this.typeWaste.uvm,
        "cover": this.typeWaste.imageurl
      })

      return; 
    }
  }

  _iniForm(){
    this.formGrp = new FormGroup({
      lib: new FormControl(
        this.typeWaste != null ? this.typeWaste.lib : null, [
          Validators.required, 
          Validators.pattern(/[0-9a-zA-Z]/)
        ], null),
      description: new FormControl(
        this.typeWaste != null ? this.typeWaste.description : null, [
          Validators.required, 
          Validators.pattern(/[0-9a-zA-Z]/)
        ], null),
      uvp: new FormControl(
        this.typeWaste != null ? this.typeWaste.uvp : null, [
          Validators.required, 
          Validators.pattern(/(^[0-9]{0,9}$)|(^[0-9]{0,9}\.[0-9]{0,3}$)/)
        ], null),
      uvm: new FormControl(
        this.typeWaste != null ? this.typeWaste.uvm : null, [
          Validators.required, 
          Validators.pattern(/(^[0-9]{0,9}$)|(^[0-9]{0,9}\.[0-9]{0,3}$)/)
        ], null),
      cover: new FormControl(
        this.typeWaste != null ? this.typeWaste.imageurl : null, [
          Validators.required,
          Validators.pattern(this.urlPattern)
        ], null)
    });
  }

  onSubmit(){
    if(this.formGrp.invalid) {
      console.log("Error adding type waste");
    }
    else {
      if(this.action.event == SubmitAction.ADDING)
        this.createEntityEvent.emit(this.formGrp.value);
      if(this.action.event == SubmitAction.EDITING) 
        this.updateEntityEvent.emit(this.formGrp.value);

      this.actionChange.emit(this.action)
    }
  }

  onReset() {
    this.formGrp.reset()
    this.typeWaste = null;
    this.typeWasteChange.emit(this.typeWaste)
    this.action = {lib: "Register", event: SubmitAction.ADDING}
  }

}
