import { Injectable } from '@angular/core';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { ITypeCustomerPayload } from './payload/type-customer.payload';

@Injectable()
export class TypeCustomerService{
  private ngxApiResources;
  constructor(private _ngxApiResources: NgxApiResources) {
    this.ngxApiResources = this._ngxApiResources.loadResources;
  }
    
  addTypeWaste(typeCustomerPayload: ITypeCustomerPayload){
    console.log(typeCustomerPayload)
    let promise = this.ngxApiResources.typeCustomerResource._addTypeCustomer({}, typeCustomerPayload).$promise;
    return promise;
  }
  
  listTypeCustomer(){
    let promise = this.ngxApiResources.typeCustomerResource._listTypeCustomer().$promise;
    return promise;
  }

  // UPDATE D'UN TYPE DE DECHET
  updateTypeCustomer(ref: string, typeWastePayload: ITypeCustomerPayload) {
    console.log(typeWastePayload)
      let promise = this.ngxApiResources.typeCustomerResource._updateTypeCustomer({ref}, typeWastePayload).$promise;

      return promise;
  }

  public toggleEnable(ref: string) {
    let promise = this.ngxApiResources.typeCustomerResource._toggleEnable({ref}).$promise;

    return promise;
  }
  public updateOrder(order: Object) {
    let promise = this.ngxApiResources.typeCustomerResource._updateOrder({},order).$promise;

    return promise;
  }
}