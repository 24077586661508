
export interface ICountryCode {
  /// the name of the country
  name: string;

  /// the flag of the country
  flagUri: string;

  /// the country code (IT,AF..)
  code: string;

  /// the dial code (+39,+93..)
  dialCode: string;

  /// the country language (fr,en,...)
  lang: string;
}
/// Country element. This is the element that contains all the information
export class CountryCode {

  constructor( private params:ICountryCode){
  }
  
 
  getCountryDialCode():string {
    return this.params.dialCode
  }

  getCountryNameAndDialCode():string {
    return this.params.dialCode + ' ' + this.params.name
  }

  getCountryName():string {
    return this.params.name
  }

  getCountryLang():string {
    return this.params.lang
  }
}
