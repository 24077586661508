import { Component, OnInit, ViewChild } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { IResponse } from 'src/app/api/models/response.interface';
import { City } from 'src/app/api/resources/cities_resource';
import { CityService } from 'src/app/services/city.service';

@Component({
  selector: 'sesa-cities',
  templateUrl: './cities.component.html',
  styleUrls: ['./cities.component.scss']
})
export class CitiesComponent implements OnInit {
  city :any;

  cities = [];
  temp = [];
  selected = [];
  sortType = SortType;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  displayTableOption: boolean = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  constructor(private cityService: CityService) { }

  ngOnInit() {
    this.getCities()
  }

  public getCities() {
    this.cityService.loadCities().then((data)=> {
      let response: IResponse = data;
      let cities : ResourceModel<City[]>;
      if(!response.error) {
        cities = response.content.sort((a,b) => 0 - (a.lib > b.lib ? -1 : 1));
        this.cities = cities
        this.temp = cities //Cache
        console.log(response.content )
      }
    }).catch((reason)=> {

    })
  }

  onSelect({ selected }){
    console.log('Select Event', this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    if(event.type == 'click'){
      this.city = event.row;
      this.displayTableOption = !this.displayTableOption
      console.log(this.city);
    }

    switch (event.type) {
      case 'click':
        this.city = event.row;
        console.log(this.selectionType);
        break;
    
      default:
        break;
    }
  }

  displayCheck(row) {
    console.log("displayTableOption : " + this.displayTableOption)
    this.displayTableOption = !this.displayTableOption
    return row;
  }

  //Sort
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    
    // filter our data
    const temp = this.temp.filter(function (d) {
      console.log(d)
      return d.lib.toLowerCase().indexOf(val) !== -1 || !val;
    });

    // update the rows
    this.cities = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.city = row
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  getRowHeight(row) {
    console.log('ROW', row);
    if (!row) {
      return 50;
    }
    if (row.height === undefined) {
      return 50;
    }
    return row.height;
  }
}
