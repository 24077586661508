import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';

/**
  * Class LanguageResource
  * @package
  * Route("")
*/
export class Language extends ResourceInstance {
    pk: number;
    url: string;
    name: string;
    snippet_count: number;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'LanguageResource',
      url: environment.apiUrl + 'language/:pk/',
      pkAttr: 'pk',
      instanceClass: Language,
      stripTrailingSlashes: false,
  })
  export class LanguageResource extends Resource<Language> {
  }
  