import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';


/**
  * Class StatsResource
  * @package
  * Route("")
*/
export class StatsCustomer extends ResourceInstance {
    nbr: string = "0";
  }
  
  export class Stats extends ResourceInstance {
    fromDate: String;
    toDate: String;
    user: string;
    typecustomer: string;
    typeWaste: string;
    totalPCI: number = 0;
    totalWCI: number = 0.0;
    totalMCI: number = 0;
    totalRPCI: number = 0;
    totalRWCI: number = 0.0;
    totalRMCI: number = 0;
    totalPCO: number = 0;
    totalWCO: number = 0.0;
    totalMCO: number = 0;
    libs: string[] = [];
    totalWCIMonthly: number[] = [];
    transactions: any[] = []
  }

  export class TopStat extends ResourceInstance {
    countCustomer: number = 0;
    accountPoints: number = 0;
    totalPCI: number = 0;
    totalWCI: number = 0.0;
    totalMCI: number = 0;
    totalPCO: number = 0;
    totalWCO: number = 0.0;
    totalOtPCI: number = 0;
    totalOtWCI: number = 0;
  }
  
  export class RealTime extends ResourceInstance {
    typeTrans: number;
    agentCover: string;
    agent: string;
    customer: string;
    totalWCI: number = 0.0;
    totalPCI: number = 0;
    totalPCO: number = 0;
    date: string;
  };

  export class TypewasteStat extends ResourceInstance {
    ref: string;
    lib: string;
    description: string;
    uvm: number;
    createdat: string;
    updatedad: string;
    enabled: boolean;
    uvp: number;
    imageurl: string;
    totalWCI: number[];
    totalPCI: number[]
  }

  export class RewardStat extends ResourceInstance {
    ref: string;
    lib: string;
    uvp: number;
    uvm: number;
    imageurl: string;
    createdat: string;
    updatedat: string;
    enabled: boolean;
    totalPCO: number;
    totalMCO: number;
  }

  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'StatsResource',
      url: environment.apiUrl, //+ 'w/stats/:pk/',//user/
      pkAttr: 'pk',
      instanceClass: Stats,
      stripTrailingSlashes: true,
  })
  export class StatsResource extends Resource<Stats | TopStat | RealTime | TypewasteStat> {
  
    constructor(
      registry: ResourceRegistry,
      http: HttpClient) {
      super(registry, http);
    }
  
    @ResourceAction(Apiroutes.poolUrl)
    _loadPool: ResourceActionMethod<any, any, Stats>;
  
    @ResourceAction(Apiroutes.statsTypeWasteUrl)
    _loadStatsTypeWaste: ResourceActionMethod<any, any, any>;
  
    @ResourceAction(Apiroutes.statsRewardUrl)
    _loadStatsReward: ResourceActionMethod<any, any, any>;
  
    @ResourceAction(Apiroutes.statsTop10TranDetailOntimeUrl)
    _loadStatsTop10TranDetailOntimeUrl: ResourceActionMethod<any, any, RealTime>;
  
    @ResourceAction(Apiroutes.statsOnPointsUrl)
    _loadStatsOnPoints: ResourceActionMethod<any, any, any>;

    @ResourceAction(Apiroutes.topStatUrl)
    _loadTopStat: ResourceActionMethod<any, any, TopStat>;
  }
  