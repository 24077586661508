import { Injectable } from '@angular/core';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { ITypeWastePayload } from './payload/type-waste.payload';
import { Subject } from 'rxjs';
import { IResources } from '../api/resources/api-resources';
import { TypeWaste } from '../api/resources/type_waste_resource';

@Injectable()
export class TypeWasteService{

  private ngxApiResources: IResources;
  addingTypeWaste: Subject<boolean> = new Subject<boolean>();

  constructor(private _ngxApiResources: NgxApiResources) {
    this.ngxApiResources = this._ngxApiResources.loadResources;
  }
  // CREATION D'UN TYPE DE DECHET
  addTypeWaste(typeWastePayload: ITypeWastePayload){
      let promise = this.ngxApiResources.typeWasteResource.save({}, typeWastePayload).$promise;

      return promise;
  }

  // UPDATE D'UN TYPE DE DECHET
  updateTypeWaste(ref: string, typeWastePayload: ITypeWastePayload) {
      let promise = this.ngxApiResources.typeWasteResource.update({ref}, typeWastePayload).$promise;

      return promise;
  }

  // LOAD LIST TYPE DECHET
  listTypeWaste(){
    let promise = this.ngxApiResources.typeWasteResource.get().$promise;
    // promise.then((data) => {
    //   //console.log(data);
    // })
    // .catch((reason) => {
    //   //console.log(reason);
    // });
    return promise;
  }

  public remove(ref: string) {
    let promise = this.ngxApiResources.typeWasteResource.delete({ref}).$promise;

    return promise;
  }

  public toggleEnable(ref: string) {
    let promise = this.ngxApiResources.typeWasteResource._toggleEnable({ref}).$promise;

    return promise;
  }

  public updateOrder(order: Object) {
    let promise = this.ngxApiResources.typeWasteResource._updateOrder({},order).$promise;

    return promise;
  }
}