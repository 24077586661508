import { Component, OnInit,AfterViewInit, ViewChild, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { dashboardTheme } from './@dashboard_theme';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { StatistiqueService } from 'src/app/services/statistique.service';
import { UserService } from 'src/app/services/user.service';
import { Chart, ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { DynamiqueForm } from 'src/app/@themes/dynamique-form';
import { ColumnMode, SelectionType,SortType, DatatableComponent } from '@swimlane/ngx-datatable';
import { TypeWasteService } from 'src/app/services/typewaste.service';
import { RewardService } from 'src/app/services/reward.service';
import { ITypeWastePayload } from 'src/app/services/payload/type-waste.payload';
import {IUser} from 'src/app/api/models/user.interface';
import { IRewardPayload } from 'src/app/services/payload/reward.payload';
import { ITypeCustomerPayload } from 'src/app/services/payload/type-customer.payload';
import { TypeCustomerService } from 'src/app/services/typecustomer.service';
import { Colors, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { IResponse } from 'src/app/api/models/response.interface';
import { Alert, ExportFile, TypeFile } from 'src/app/utils/utils';
import { RealTime, RewardStat, Stats, TopStat, TypewasteStat } from 'src/app/api/resources/stats_resource';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';
import { Customer } from 'src/app/api/resources/customer_resource';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';
import { Reward } from 'src/app/api/resources/reward_resource';
import { CustomerService } from 'src/app/services/customer.service';
import { sparklineInit } from 'src/app/@themes/sparkline-active';
import { animationInit } from 'src/app/@themes/animation-active';
import { flotCurveLine } from 'src/app/@themes/flotcurveline';
import { dataTableInit } from 'src/app/@themes/data-table-act';
import { User } from 'src/app/api/resources/user_resource';
import * as XLSX from 'xlsx';
import { ifStmt } from '@angular/compiler/src/output/output_ast';
import * as moment from 'moment';
//import  * as $ from 'jquery';

declare const $: any;
declare const swal: any;

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';

export interface Task {
  libele: string;
  name: string;
  checked: boolean;
  diseable: boolean;
}

export interface TransactionHeaders {
  ref: string;
  type: string;
  title: string;
  agent_first_name: string;
  agent_last_name: string;
  customer_first_name: string;
  customer_last_name: string;
  weight: string;
  points: string;
  amount:string;
  date: string;
}

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'sesa-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit,AfterViewInit {

  transactionHeaders: TransactionHeaders = {
    ref: "ref",
    type: "type",
    title: "title",
    agent_first_name: "agent_first_name",
    agent_last_name: "agent_last_name",
    customer_first_name: "customer_first_name",
    customer_last_name: "customer_last_name",
    weight: "weight",
    points: "points",
    amount: "amount",
    date: "date"
  }
  tasks: Task[] = [
    {
      libele: "ref",
      name: "Ref.",
      checked: true,
      diseable: true
    },
    {
      libele: "type",
      name: "Type",
      checked: true,
      diseable: false
    },
    {
      libele: "title",
      name: "Title",
      checked: true,
      diseable: false
    },
    {
      libele: "agent_first_name",
      name: "First name(agent)",
      checked: true,
      diseable: false
    },
    {
      libele: "agent_last_name",
      name: "Last name(agent)",
      checked: false,
      diseable: false
    },
    {
      libele: "customer_first_name",
      name: "Firt name(customer)",
      checked: false,
      diseable: false
    },
    {
      libele: "customer_last_name",
      name: "Last name(customer)",
      checked: false,
      diseable: false
    },
    {
      libele: "weight",
      name: "Weight",
      checked: true,
      diseable: false
    },
    {
      libele: "points",
      name: "Points",
      checked: true,
      diseable: false
    },
    {
      libele: "amount",
      name: "Amount",
      checked: true,
      diseable: false
    },
    {
      libele: "date",
      name: "Date",
      checked: true,
      diseable: false
    }
  ]

  // Chart 0 : barChart : Line
  public barLineChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barLineChartPlugins = [pluginDataLabels];
  public barLineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','Sept','Oct','Nov','Dec'];
  public barLineChartData: ChartDataSets[] = [{ data: [], label: 'Waste' }];
  public barLineChartType: ChartType = 'line';
  public barLineChartLegend = true;

  // Chart 1
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
      }
    }
  };
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;

  public barChartPlugins = [pluginDataLabels];
  public barChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July','August','Sept','Oct','Nov','Dec'];
  public barChartData: ChartDataSets[] = [
    { data: [], label: 'Waste' },
    { data: [], label: 'Reward' },
    { data: [], label: 'weight' }
  ];

  // Chart 2
  public barChartOptionsWaste: ChartOptions = {
    responsive: true,
    aspectRatio: 0.7,
    spanGaps: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'center',
      }
    }
  };
  // public barColorsWaste: Colors[] = [
  //   {
  //     backgroundColor: ['rgba(255,0,0,0.0)'],
  //   },
  // ];
  public barChartTypeWaste: ChartType = 'bar';
  public barChartPluginsWastes = [pluginDataLabels]
  public barChartLabelsWaste: Label[] = ['', '', '', '', '', '', ''];
  public barChartDataWasteWeight: ChartDataSets[] = [{ data: [], label: 'weight' }];
  public barChartDataWastePoints: ChartDataSets[] = [{ data: [], label: 'Points' }];

  // Chart 3
  public barChartOptionsReward: ChartOptions = {
    responsive: true,
    aspectRatio: 0.7,
    spanGaps: true,
    scales: { xAxes: [{}], yAxes: [{}] },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'center',
      }
    }
  };
  public barChartTypeReward: ChartType = 'horizontalBar';
  public barChartPluginsReward = [pluginDataLabels]
  public barChartLabelsReward: Label[] = [];
  public barChartDataRewardPoints: ChartDataSets[] = [
    { data: [], label: 'point' }
  ];
  public barChartDataRewardAmount: ChartDataSets[] = [
    { data: [], label: 'Amount' }
  ];

  // Pie
  public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'right',
    },
  };
  public pieChartLabels: Label[] = ["Waste", "Rewards"];
  public pieChartData: SingleDataSet = [];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieColors: Colors[] = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)'],
    },
  ];

  // Customer table
  customers :any;
  rows = [];
  temp = [];
  selected = [];
  sortType = SortType;
  columnMode = ColumnMode;
  SelectionType = SelectionType;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  loadingIndicator = true;

  //Group
  funder = [];
  calculated = [];
  pending = [];
  groups = [];
  editing = {};

  lineChart: Chart;
  barChart: Chart;

  //@ViewChild('canvasEl') canvasEl: ElementRef
  //private context: CanvasRenderingContext2D;

  stat : ResourceModel<Stats>;
  topStat: ResourceModel<TopStat>;
  allTypeWaste : ResourceModel<TypeWaste>[] = [];

  listTypeWasteStat: ResourceModel<TypewasteStat>[]=[];


  allRewards: ResourceModel<Reward>[] = [];

  addRewardFlashBag: Alert;
  listRewardStat: ResourceModel<RewardStat>[] = [];

  listCustomers : ResourceModel<Customer>[] = [];

  errorAddTypeCustomer: string;
  listTypeCustomers : ResourceModel<TypeCustomer>[] = [];
  listTypeCustomerForm ;

  listUsers:  ResourceModel<User>[] = [];
  userSelected: User;

  listTop10s: ResourceModel<RealTime>[] =  [];

  typeFileXlsx: TypeFile = TypeFile.EXCEL;
  typeFileCsv: TypeFile = TypeFile.CSV;
  typeFileJson: TypeFile = TypeFile.JSON;

  tempTransactions = [];

  constructor(
    private statistiqueService: StatistiqueService,
    private typeWasteService: TypeWasteService,
    private rewardService: RewardService,
    private typeCustomerService: TypeCustomerService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    private userService: UserService
    ) {

    monkeyPatchChartJsTooltip();
    monkeyPatchChartJsLegend();

  }

  ngOnInit() {
    //this.context = (this.canvasEl.nativeElement as HTMLCanvasElement).getContext('2d');
    //this.drawLineChart();
    //this.drawBarChart();
    //table.buttons().container().appendTo( '#example_wrapper .col-sm-6:eq(0)' );
    this.addRewardFlashBag = {visibility: false,message: "",backgroudColor: ""}
    //this.pieChartData = [300, 500];

    this.getTopStat();
    this.getTypeWastes();
    this.getRewards();
    this.getTypeCustomers();
    this.getCustomers();
    this.loadUsers();
  }


  ngAfterViewInit() {
    //dashboardTheme();
    sparklineInit();
    //animationInit($);
    //flotCurveLine($);
    //dataTableInit($);
    //this.listTypeCustomerForm = new DynamiqueForm({data:[]})
    //this.listTypeCustomerForm.init();
  }

  // TOP STAT
  getTopStat() {
    this.statistiqueService.getTopStat().then((data)=> {
      let response: IResponse = data;
      if(!response.error) {
        this.topStat = response.content;
      }
    }).catch((reason)=> {
      //console.log(reason)
    })
  }

  // STATS
  public getStat(dateRange: Object) {
    this.loadingIndicator = true;
    this.spinner.show("stat-pool");
    this.statistiqueService.getPool(dateRange).then((data)=> {
      let response: IResponse = data;

      if(!response.error) {
        this.stat = response.content;
        this.tempTransactions = this.stat.transactions;
        this.barLineChartData = [
          { data: this.stat.totalWCIMonthly, label: 'Waste' }
        ]
        this.loadingIndicator = false;
        this.spinner.hide("stat-pool");
        //this.pieChartData = [this.statistiques.todaysTotalDebit, this.statistiques.todaysTotalCredit];
      }
    }).catch((reason)=> {
      console.log(reason)
      this.loadingIndicator = false;
      this.spinner.hide("stat-pool");
    })
  }

  // TYPEWASTE
  private getTypeWastes() {
    this.typeWasteService.listTypeWaste().then((data)=> {
      let response: IResponse = data;
      if(!response.error){
        this.allTypeWaste = response.content;
      }
    }).catch((reason)=> {
      //console.log(reason)
    })
  }

  // STATS OF TYPE WASTE
  private loadTypeWasteStats(dateRange: Object) {
    this.spinner.show("stat-typeofwaste")
    this.statistiqueService.getTypeWasteStat(dateRange).then((data) =>{
      this.spinner.hide("stat-typeofwaste")
      let response: IResponse = data
      if(!response.error){
        this.listTypeWasteStat = response.content;

        let libs: any[]=[];
        let listTotalWCI: any[]=[];
        let listTotalPCI: any[]=[];

        this.listTypeWasteStat.forEach((item,i)=> {
          libs[i] = item.lib
          listTotalWCI[i] = item.totalWCI
          listTotalPCI[i] = item.totalPCI
        })

        this.barChartLabelsWaste = libs;
        this.barChartDataWasteWeight = [{ data: listTotalWCI, label: 'weight' }]
        this.barChartDataWastePoints = [{ data: listTotalPCI, label: 'Points' }]

      }
    }).catch((reason) => {
      console.log(reason);
    });
  }

  // GET TYPEWASTE STAT
  public getTypeWastesByDataRange(eventdata: Object) {
    this.loadTypeWasteStats(eventdata)
  }

  // REWARD
  private getRewards() {
    this.rewardService.loadRewards().then((data)=> {
      let response: IResponse = data;
      if(!response.error) {
        this.allRewards = response.content;
      }
    }).catch((reason)=> {

    })
  }

  // STATS REWARDS
  public loadRewardStats(dateRange: Object) {
    this.spinner.show("stat-reward")
    this.statistiqueService.getRewardStat(dateRange).then((data) =>{
      this.spinner.hide("stat-reward")
      let response: IResponse = data

      if(!response.error){
        this.listRewardStat = response.content;

        let libs: any[]=[];
        let listTotalPCO: any[]=[];
        let listTotalMCO: any[]=[];
        this.listRewardStat.forEach((item,i)=> {
          libs[i] = item.lib
          listTotalPCO[i] = item.totalPCO
          listTotalMCO[i] = item.totalMCO
        })

        this.barChartLabelsReward = libs
        this.barChartDataRewardPoints = [{ data: listTotalPCO, label: 'point' }]
        this.barChartDataRewardAmount = [{ data: listTotalMCO, label: 'Amount' }]
      }
    }).catch((reason) => {
      //console.log(reason);
    });
  }

  // GET REWARD STAT
  public getRewardsStatByDataRange(eventdata: Object) {
    this.loadRewardStats(eventdata)
  }

  // TypeCustomer
  public getTypeCustomers() {
    this.typeCustomerService.listTypeCustomer().then((data)=> {
      let response: IResponse = data;
      if(!response.error) {
        this.listTypeCustomers =  response.content.sort((a,b) => 0 - (a > b ? -1 : 1));
        this.listTypeCustomers .forEach(listTypeCustomer => {
          this.listTypeCustomerForm.addData(listTypeCustomer.lib)

        });
      }
    }).catch((reason)=> {

    })
  }

  // Customer
  public getCustomers() {
    this.customerService.listCustomer().then((data)=> {
      let response: IResponse = data;
      if(!response.error) {
        this.listCustomers = response.content.sort((a,b) => 0 - (a > b ? -1 : 1));
        this.rows = this.listCustomers
        this.temp = this.listCustomers //Cache
        console.log(response.content )
      }
    }).catch((reason)=> {

    })
  }

  public getListCustomers() {
    return this.listCustomers;
  };

  // LOAD USERS
  public loadUsers() {
    this.userService.getListUser().then((data)=> {
      let response: IResponse = data
      if(!response.error) {
        this.listUsers = response.content;
      }

    }).catch((reason)=>{
      console.log(reason)
    })
  }

  public getCurrentUserSelected(user: User){
    this.userSelected = user;
  }

  public getTop10TranDetailOntime(dateRange: Object) {
    this.spinner.show("stat-top10pickup")
    this.statistiqueService.getTop10TranDetailOntime(dateRange).then((data)=> {
      this.spinner.hide("stat-top10pickup")
      let response: IResponse = data
      if(!response.error) {
        this.listTop10s = response.content;
      }
    }).catch((reason)=>{
      console.log(reason);
    })
  }

  // GET TOP 10 PICKUP BY DATE RANGE
  public getTop10PickupByDateRange(eventdata: Object) {
    this.getTop10TranDetailOntime(eventdata)
  }

  public getStatsOnPoints() {
    this.statistiqueService.getStatsOnPoints().then((data)=> {
      let response: IResponse = data
      if(!response.error) {
        this.barChartData = [
          { data: response.content['points']['debit'], label: 'Waste' },
          { data: response.content['points']['credit'], label: 'Reward' },
          { data: response.content['weight'], label: 'weight' }
        ]
      }
    }).catch((reason)=> {
      //console.log(reason);
    })
  }

  //Customer ngx-datatable
  onSelect({ selected }){
    //console.log('Select Event', this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    //console.log('Activate Event', event);
    switch (event.type) {
      case 'click':
        this.customers = event.row;
        console.log(this.customers);
        break;

      default:
        break;
    }
  }


  //CUSTOMER NGX-DATATABLE GROUP

  // toggleExpandGroup(group) {
  //   console.log('Toggled Expand Group!', group);
  //   this.table.groupHeader.toggleExpandGroup(group);
  // }

  //CHART
  /*drawLineChart(){
    this.lineChart = new Chart(this.context,{
      type: 'line',
      data: {
        labels: ['Janv','Feb'],
        datasets: [{
          label: '4number of',
          data: [1,2],
          fill: false,
          lineTension: 0.2,
          borderColor: 'red',
          borderWidth: 1
        }]
      }
    })
  }

  drawBarChart() {
    this.barChart = new Chart(this.context, {
      type: 'bar',
      data: {
        labels: [0, 1, 2, 3],
        datasets: [{
          label: 'Bar Chart',
          data: [12, 19, 3, 5, 2, 3],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',
            'rgb(50,205,50, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)'
          ],
          borderColor: [
            'rgba(255,99,132,1)',
            'rgba(54, 162, 235, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
        }]
      },
      options: {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero:true
            }
          }]
        }
      }
    })
  }*/

  // More-Checkbox
  setChecked(index: number, checked: boolean) {
    this.tasks[index].checked = checked;
  }

  // EXCEL
  exportTo(type: TypeFile)
  {

    let temp: any[] = [];
    let sheetHeader = [];
    let sheetRow: any[] = [];
    // CONFIGURATION ENTETE
    this.tasks.forEach((t)=>{
      if((t.checked && t.libele == this.transactionHeaders.ref) ||
        (t.checked && t.libele == this.transactionHeaders.type) ||
        (t.checked && t.libele == this.transactionHeaders.title) ||
        (t.checked && t.libele == this.transactionHeaders.agent_first_name) ||
        (t.checked && t.libele == this.transactionHeaders.customer_first_name) ||
        (t.checked && t.libele == this.transactionHeaders.weight) ||
        (t.checked && t.libele == this.transactionHeaders.points) ||
        (t.checked && t.libele == this.transactionHeaders.amount) ||
        (t.checked && t.libele == this.transactionHeaders.date)
      )
        sheetHeader.push(t.name)
    })


    // GENERATION DE LIGNE DE DONNEES
    for (let i = 0; i < this.stat.transactions.length; i++) {
      let transaction = this.stat.transactions[i];
      let tabTrans = [];
      this.tasks.forEach((t)=>{
        if(t.checked && t.libele == this.transactionHeaders.ref)
          tabTrans.push(transaction.ref)
        if(t.checked && t.libele == this.transactionHeaders.type)
          tabTrans.push(transaction.type == 0 ? "Reward" : transaction.type == 1 ? "Collecte" : "Refund")
        if(t.checked && t.libele == this.transactionHeaders.title)
          tabTrans.push(transaction.lib)
        if(t.checked && t.libele == this.transactionHeaders.agent_first_name)
          tabTrans.push(transaction.agent.firstname)
        if(t.checked && t.libele == this.transactionHeaders.customer_first_name)
          tabTrans.push(transaction.customer.name)
        if(t.checked && t.libele == this.transactionHeaders.weight)
          tabTrans.push(transaction.weight)
        if(t.checked && t.libele == this.transactionHeaders.points)
          tabTrans.push(transaction.point)
        if(t.checked && t.libele == this.transactionHeaders.amount)
          tabTrans.push(transaction.point)
        if(t.checked && t.libele == this.transactionHeaders.date)
          tabTrans.push(transaction.createdat)
      })

      sheetRow.push(...[
        tabTrans
      ])
    }

    if(sheetHeader.length > 0) {
      temp.push(...[
        sheetHeader
      ])
    }
    temp.push(...sheetRow)

    let _now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    let exportFile = new ExportFile()
    switch (type) {
      case TypeFile.EXCEL:
        exportFile.setSheetName(_now)
        exportFile.setFileName("transactions_"+_now + ".xlsx")
        exportFile.setData(temp)

        exportFile.toExcel()
        break;
      case TypeFile.CSV:
        exportFile.setSheetName("Sheet1")
        exportFile.setFileName("transactions_"+_now + ".csv")
        exportFile.setData(temp)
        exportFile.toCsv()
        break;

      case TypeFile.JSON:

        break;
      default:
        exportFile.setSheetName("Sheet1")
        exportFile.setFileName("transactions_"+_now + ".xlsx")
        exportFile.setData(temp)
        exportFile.toExcel()
        break;
    }

    // const ws: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(temp);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.utils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]], { FS: ";", strip: true });

    // /* save to file */
    // XLSX.writeFile(wb, 'transactions.xlsx');

  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.tempTransactions.filter(function (d) {
      console.log(d)
      return d.lib.toLowerCase().indexOf(val) !== -1 ||
      d.agent.firstname.toLowerCase().indexOf(val) !== -1 ||
      d.customer.name.toLowerCase().indexOf(val) !== -1 ||
      (d.type == 0 ? "Reward".toLowerCase().indexOf(val) !== -1 : d.type == 1 ? "Collecte".toLowerCase().indexOf(val) !== -1 : "Refund".toLowerCase().indexOf(val) !== -1) ||
      d.createdat.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });

    // update the rows
    this.stat.transactions = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }
}
