import { Injectable } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { IResources } from '../api/resources/api-resources';
import { IAuthPayload } from './payload/auth.payload';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { IResponse } from '../api/models/response.interface';
import { User } from '../api/resources/user_resource';
import { Router } from '@angular/router';
import { LocalStorageService } from 'ngx-webstorage';
import { AUTH_TOKEN, CURRENT_USER } from '../utils/utils';
import { TokenHelperService } from './tokenHelper.service';

@Injectable()
export class AuthService {
    isLoggedIn = false;
    currentUser: ResourceModel<User> = null;
    loginStatusUpdates: Subject<boolean> = new Subject<boolean>();
    private ngxApiResources: IResources;

    constructor(
      private _ngxApiResources: NgxApiResources,
      private storage: LocalStorageService,
      private tokenHelperService: TokenHelperService) {
      this.ngxApiResources = this._ngxApiResources.loadResources;
    }

  public isUserLoggedIn(): boolean {
    return this.tokenHelperService.getToken() != null && !this.tokenHelperService.isExpired();
  }

  public login(authPayload: IAuthPayload) {

    let promise = this.ngxApiResources.userResource._authenticate({}, authPayload).$promise;
    return promise;
  }

  // Log out user
  public logout() {
    this.tokenHelperService.removeToken();
    this.storage.clear(CURRENT_USER);
  }
}
