import { ModuleWithProviders, NgModule } from '@angular/core';
import {  NgxApiResources } from './api_services/ngx-api-resources';

@NgModule({
    imports: [],

    exports: [],
        declarations: [],
        providers: [
            
        ],
    }
)
export class NgxApiModule {

    static forRoot(): ModuleWithProviders<NgxApiModule> {
        return {
            ngModule: NgxApiModule,
            providers: [
                NgxApiResources
            ]
        };
    }
}
