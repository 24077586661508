import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './layout/pages/dashboard/dashboard.component';
import { CrmComponent } from './layout/pages/crm/crm.component';
import { SettingsComponent } from './layout/pages/settings/settings.component';
import { WastesComponent } from './layout/pages/wastes/wastes.component';
import { RewardsComponent } from './layout/pages/rewards/rewards.component';
import { AgentComponent } from './layout/pages/agent/agent.component';
import { CustomerComponent } from './layout/pages/customer/customer.component';
import { CitiesComponent } from './layout/pages/cities/cities.component';
import { AuthGuardService } from './services/AuthGuardService';
import { LoginComponent } from './components/login/login.component';
import { BaseComponent } from './layout/base/base.component';
import { TransactionsComponent } from './layout/pages/transactions/transactions.component';
import { TypeCustomerComponent } from './layout/pages/type-customer/type-customer.component';

const routes: Routes = [
  { 
    path: "", 
    component: BaseComponent,
    children: [
      { path: "dashboard",  component: DashboardComponent},
      { path: "crm/agents", component: AgentComponent},
      { path: "crm/customers", component: CustomerComponent},
      // { path: "settings/:args", component: SettingsComponent},
      { path: "settings/wastes", component: WastesComponent},
      { path: "settings/rewards", component: RewardsComponent},
      { path: "settings/cities", component: CitiesComponent},
      { path: "settings/type-customer", component: TypeCustomerComponent},
      { path:  "reporting/transactions", component: TransactionsComponent}
      
    ],
    canActivate: [AuthGuardService] 
  },
  
  { path: "login", component: LoginComponent},
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
