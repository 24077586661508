import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';

/**
  * Class TypeWasteResource
  * @package
  * Route("")
*/
export class TypeWaste extends ResourceInstance {
    ref: string;
    lib: string;
    description: string;
    uvm: number;
    createdat: string;
    updatedad: string;
    enabled: boolean;
    uvp: number;
    imageurl: string;
    ord: number;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'TypeWasteResource',
      url: environment.apiUrl, //+'w/typeWaste/:ref/', //+ 'settings/:pk/',//user/
      pkAttr: 'ref',
      instanceClass: TypeWaste,
      stripTrailingSlashes: true,
  })
  export class TypeWasteResource extends Resource<TypeWaste> {
  
  
    constructor(
      registry: ResourceRegistry,
      http: HttpClient) {
      super(registry, http);
    }
  
    @ResourceAction(Apiroutes.addTypeWasteUrl)
    save: ResourceActionMethod<any, any, TypeWaste>;

    @ResourceAction(Apiroutes.updateTypeWasteUrl)
    update: ResourceActionMethod<any, any, TypeWaste>;
  
    @ResourceAction(Apiroutes.listTypeWasteUrl)
    get: ResourceActionMethod<any, any, TypeWaste>;
  
    @ResourceAction(Apiroutes.deleteTypeWasteUrl)
    delete: ResourceActionMethod<any, any, TypeWaste>;

    @ResourceAction(Apiroutes.toggleEnableTypeWasteUrl)
    _toggleEnable: ResourceActionMethod<any, any, TypeWaste>;

    @ResourceAction(Apiroutes.updateOrderTypeWasteUrl)
    _updateOrder: ResourceActionMethod<any, any, TypeWaste>;
  }