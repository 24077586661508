import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ColumnMode, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IResponse } from 'src/app/api/models/response.interface';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';
import { ITypeWastePayload } from 'src/app/services/payload/type-waste.payload';
import { TypeWasteService } from 'src/app/services/typewaste.service';
import { Alert, IAction, IUserAction, map2Obj, SubmitAction } from 'src/app/utils/utils';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { UserService } from 'src/app/services/user.service';

declare const $: any;
declare const swal: any;

@Component({
  selector: 'sesa-wastes',
  templateUrl: './wastes.component.html',
  styleUrls: ['./wastes.component.scss']
})
export class WastesComponent implements OnInit,OnChanges {
  userActions: IUserAction;
  selected = [];
  rowSelected: any;
  SortType = SortType;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  @Input() loadingIndicator = true;

  listTypeWaste: ResourceModel<TypeWaste[]>;
  flashBag: Alert;
  typeWasteSelected: TypeWaste;
  errorAddTypeWatse: string;
  submitAction: IAction = {lib: "Register",event: SubmitAction.ADDING};

  isUpdateOrderOn=false;
  status: IResponse;

  isLoading: boolean = false;

  constructor(
    private typeWasteService: TypeWasteService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) {
    this.userActions = this.userService.getCurrentUserActions()
   }

  ngOnInit() {
    this.flashBag = {visibility: false,message: "",backgroudColor: ""}
    this.loadListTypeWaste()
  }

  ngOnChanges() {
    console.log(this.submitAction)
  }
  onSelect({ selected }){
    console.log('Select Event', this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    if(event.type == 'click'){
      this.rowSelected = event.row;
      console.log(event.row);
    }
  }

  //Load Type dechet
  public loadListTypeWaste(){
    this.isLoading = true
    this.typeWasteService.listTypeWaste().then((data)=>{
      this.isLoading = false
      let response: IResponse = data
      if(!response.error){
        this.listTypeWaste = response.content as ResourceModel<TypeWaste[]>;
      }

    }).catch((error)=>{

    });
  }

  onUpdateTypeWaste(typeWastePayload: ITypeWastePayload){
    this.spinner.show()
    let promise = this.typeWasteService.updateTypeWaste(this.typeWasteSelected.ref,typeWastePayload);
    
    promise.then((data) => {
      let response: IResponse = data;

      this.spinner.hide()
      
      if(!response.error) {
        this.flashBag =  { visibility: true,message: response.message,backgroudColor: "alert alert-success alert-dismissible"}
      
        swal("Success!", "Waste successfuly has been updated", "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.loadListTypeWaste();
      }
      else {
        this.errorAddTypeWatse = response.message
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        }
        swal({
          icon: 'error',   
          title: "Ooops !",   
          text: this.flashBag.message,   
          type: "warning",   
          showCancelButton: false,   
          confirmButtonText: "Ok",
        })
      }

      this.status = response
    })
    .catch((reason) => {
      this.spinner.hide()

      this.status = {
        error: true,
        statusCode: 301,
        version: "v1",
        message: "An error has been detected, please contact the support at support@sesa.tech",
        content: "An error has been detected, please contact the support at support@sesa.tech"
      }
      
      swal({
        icon: 'error',   
        title: "Ooops !",   
        text: "An error has been detected, please contact the support at support@sesa.tech",   
        type: "warning",   
        showCancelButton: false,   
        confirmButtonText: "Ok",
      })
    });
  }
  
  onCreateTypeWaste(typeWastePayload: ITypeWastePayload){
    this.spinner.show()
    let promise = this.typeWasteService.addTypeWaste(typeWastePayload);
    
    promise.then((data) => {
      let response: IResponse = data;
      this.spinner.hide()
      
      if(!response.error) {
        this.flashBag =  { visibility: true,message: response.message,backgroudColor: "alert alert-success alert-dismissible"}
        
        swal("Success!", "Waste successfuly has been added", "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.loadListTypeWaste();
      }
      else {
        this.errorAddTypeWatse = response.message
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        }
        
        swal({
          icon: 'error',   
          title: "Ooops !",   
          text: this.flashBag.message,   
          type: "warning",   
          showCancelButton: false,   
          confirmButtonText: "Ok",
        })
      }
      this.status = response
    })
    .catch((reason) => {
      this.spinner.hide()
      this.status = {
        error: true,
        statusCode: 301,
        version: "v1",
        message: "An error has been detected, please contact the support at support@sesa.tech",
        content: "An error has been detected, please contact the support at support@sesa.tech"
      }
      swal({
        icon: 'error',   
        title: "Ooops !",   
        text: "An error has been detected, please contact the support at support@sesa.tech",   
        type: "warning",   
        showCancelButton: false,   
        confirmButtonText: "Ok",
      })
    });
  }

  updateTypeWaste(typeWastePayload: ITypeWastePayload) {

  }

  onTypeWasteAlertDialogue(ref: string) {
    swal({
			title: "Are you sure?",
			text: "You will not be able!",
			type: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		}).then((data)=>{
      console.log(data)
      this.typeWasteService.remove(ref).then((data)=> {
        this.loadListTypeWaste();
        swal("Deleted!", "Your imaginary file has been deleted.", "success");
      }).catch((reason)=> {
        console.log(reason);
        swal("Oops!", "An error is detected.", "success");
      })

    });
  }

  onTypeWasteSelected(t) {
    this.typeWasteSelected = t;
    this.submitAction = {lib: "Update", event: SubmitAction.EDITING}
  }

  onToggleEnabled(typeWasteSelected: TypeWaste) {
      this.typeWasteService.toggleEnable(typeWasteSelected.ref).then((data)=> {
      let response: IResponse = data;

      if(!response.error) {
        this.loadListTypeWaste()
      }
    }).catch((reason)=> {
      console.log(reason)
    })
  }

  // LIST DRAG AND DROP
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listTypeWaste, event.previousIndex, event.currentIndex);
    this.isUpdateOrderOn=true;
  }

  public updateOrder() {
    if(this.isUpdateOrderOn) {
      let params: Map<number, string> = new Map();
      this.listTypeWaste.forEach((typseWaste,i) =>{
        params.set(i,typseWaste.ref);
      })
      
      this.spinner.show("sp-order");
      this.typeWasteService.updateOrder(map2Obj(params)).then((data)=> {
        let response: IResponse = data;
        if(!response.error){
          //console.log(response.message)
        }
        else {
          //console.log(response.message)
        }
        this.spinner.hide("sp-order");
        this.isUpdateOrderOn = false;
      }).catch((reason)=> {
        this.isUpdateOrderOn = false;
        //console.log(reason)
      })
    } 
  }

}
