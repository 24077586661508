import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'sesa-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss']
})
export class ToggleComponent implements OnInit {

  @Output() changed = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }
  
}
