import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ColumnMode, DatatableComponent, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { IResponse } from 'src/app/api/models/response.interface';
import { StatsUser } from 'src/app/api/resources/user_resource';
import { UserService } from 'src/app/services/user.service';
import { ExportFile, TypeFile } from 'src/app/utils/utils';
import * as moment from 'moment';

export interface Task {
  libele: string;
  name: string;
  checked: boolean;
  diseable: boolean;
}

export interface TransactionHeaders {
  ref       : string
  firstname : string
  lastname  : string
  gsm       : string
  address   : string
  city      : string
  email     : string
  gender    : string
  enabled   : string
  createdat: string;
  
  totalWCI: string;
  totalPCI: string;
  totalMCI: string;

  totalRWCI: string;
  totalRPCI: string;
  totalRMCI: string;

  totalPCO: string;
  totalWCO: string;
  totalMCO: string;
}

@Component({
  selector: 'sesa-transactions',
  templateUrl: './transactions.component.html',
  styleUrls: ['./transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TransactionsComponent implements OnInit,AfterViewInit {
  typeFileXlsx: TypeFile = TypeFile.EXCEL;
  typeFileCsv: TypeFile = TypeFile.CSV;
  typeFileJson: TypeFile = TypeFile.JSON;

  userHeaders: TransactionHeaders = {
    ref       : "ref",
    firstname : "firstname",
    lastname  : "lastname",
    gsm       : "gsm",
    address   : "address",
    city      : "city",
    email     : "email",
    gender    : "gender",
    enabled   : "enabled",
    createdat : "createdat",
    totalWCI  : "totalWCI",
    totalPCI  : "totalPCI",
    totalMCI  : "totalMCI",

    totalRWCI : "totalRWCI",
    totalRPCI : "totalRPCI",
    totalRMCI : "totalRMCI",

    totalPCO  : "totalPCO",
    totalWCO  : "totalWCO",
    totalMCO  : "totalMCO"
  }
  tasks: Task[] = [
    {
      libele: "ref",
      name: "Ref.",
      checked: true,
      diseable: true
    },
    {
      libele: "firstname",
      name: "firstname",
      checked: true,
      diseable: false
    },
    {
      libele: "lastname",
      name: "lastname",
      checked: true,
      diseable: false
    },
    {
      libele: "gsm",
      name: "gsm",
      checked: false,
      diseable: false
    },
    {
      libele: "address",
      name: "address",
      checked: false,
      diseable: false
    },
    {
      libele: "city",
      name: "city",
      checked: false,
      diseable: false
    },
    {
      libele: "email",
      name: "email",
      checked: true,
      diseable: false
    },
    {
      libele: "gender",
      name: "gender",
      checked: true,
      diseable: false
    },
    {
      libele: "enabled",
      name: "enabled",
      checked: true,
      diseable: false
    },
    {
      libele: "createdat",
      name: "Date",
      checked: true,
      diseable: false
    },
    {
      libele: "totalWCI",
      name: "Collecte weight",
      checked: true,
      diseable: false
    },
    {
      libele: "totalPCI",
      name: "Collecte points",
      checked: true,
      diseable: false
    },
    {
      libele: "totalMCI",
      name: "Collecte amount",
      checked: true,
      diseable: false
    },
    {
      libele: "totalRWCI",
      name: "Refund weight",
      checked: true,
      diseable: false
    },
    {
      libele: "totalRPCI",
      name: "Refund points",
      checked: true,
      diseable: false
    },
    {
      libele: "totalRMCI",
      name: "Refund amount",
      checked: true,
      diseable: false
    }
  ]
  //@ViewChild('myTable') table: any;

  user: any;
  users = [];

  expanded: any = {};
  temp = [];
  selected = [];
  sortType = SortType;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  displayTableOption: boolean = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.getUsersStat({})
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.table.rowDetail.expandAllRows()
    }, 1000);
    
  }

  public getUsersStat(dateRange: Object) {
    this.userService.loadStatsUsers(dateRange).then((data)=> {
      let response: IResponse = data;
      let users: ResourceModel<StatsUser[]>;
      
      if(!response.error) {
        users = response.content;
        this.users = users;
        this.temp = users //Cache
        
        console.log("Users Stats" )
        console.log(response.content)
      }
    }).catch((reason)=> {

    })
  }

  onSelect({ selected }){
    console.log('Select Event', this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    
    if(event.type == 'click'){
      this.user = event.row;
      this.displayTableOption = !this.displayTableOption
      console.log(this.user);
    }

    switch (event.type) {
      case 'click':
        this.user = event.row;
        console.log(this.selectionType);
        break;
    
      default:
        break;
    }
  }

  //Sort table
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    
    // filter our data
    const temp = this.temp.filter(function (d) {
      console.log(d)
      return d.firstname.toLowerCase().indexOf(val) !== -1 || 
      d.lastname.toLowerCase().indexOf(val) !== -1 ||
      d.gender.toLowerCase().indexOf(val) !== -1 ||
      d.city.toLowerCase().indexOf(val) !== -1 || 
      d.gsm.toLowerCase().indexOf(val) !== -1 ||
      d.createdat.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });

    // update the rows
    this.users = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }

  // GET TOP 10 PICKUP BY DATE RANGE
  public getStatTransByDateRange(eventdata: Object) {
    this.getUsersStat(eventdata)
  }

  // EXCEL
  exportTo(type: TypeFile)
  {
    console.log(this.users)
    let temp: any[] = [];
    let sheetHeader = [];
    let sheetRow: any[] = [];
    // CONFIGURATION ENTETE
    this.tasks.forEach((t)=>{
      if((t.checked && t.libele == this.userHeaders.ref) ||
        (t.checked && t.libele == this.userHeaders.firstname) ||
        (t.checked && t.libele == this.userHeaders.lastname) ||
        (t.checked && t.libele == this.userHeaders.gsm) ||
        (t.checked && t.libele == this.userHeaders.address) ||
        (t.checked && t.libele == this.userHeaders.city) ||
        (t.checked && t.libele == this.userHeaders.email) ||
        (t.checked && t.libele == this.userHeaders.gender) ||
        (t.checked && t.libele == this.userHeaders.enabled) ||
        (t.checked && t.libele == this.userHeaders.createdat) ||
        (t.checked && t.libele == this.userHeaders.totalWCI) ||
        (t.checked && t.libele == this.userHeaders.totalPCI) ||
        (t.checked && t.libele == this.userHeaders.totalMCI) ||
        (t.checked && t.libele == this.userHeaders.totalRWCI) ||
        (t.checked && t.libele == this.userHeaders.totalRPCI) ||
        (t.checked && t.libele == this.userHeaders.totalRMCI)
      )
        sheetHeader.push(t.name)
    })
    
  
    // GENERATION DE LIGNE DE DONNEES
    for (let i = 0; i < this.users.length; i++) {
      let user = this.users[i] as StatsUser;
      let tabUsers = [];

      this.tasks.forEach((t)=>{
        if(t.checked && t.libele == this.userHeaders.ref)
          tabUsers.push(user.ref)
        
        if(t.checked && t.libele == this.userHeaders.firstname)
          tabUsers.push(user.firstname)

        if(t.checked && t.libele == this.userHeaders.lastname)
          tabUsers.push(user.lastname)

        if(t.checked && t.libele == this.userHeaders.gsm)
          tabUsers.push(user.gsm)

        if(t.checked && t.libele == this.userHeaders.address)
          tabUsers.push(user.address)

        if(t.checked && t.libele == this.userHeaders.city)
          tabUsers.push(user.city)

        if(t.checked && t.libele == this.userHeaders.email)
          tabUsers.push(user.email)

        if(t.checked && t.libele == this.userHeaders.gender)
          tabUsers.push(user.gender)

        if(t.checked && t.libele == this.userHeaders.enabled)
          tabUsers.push(user.enabled)

        if(t.checked && t.libele == this.userHeaders.createdat)
          tabUsers.push(user.createdat)

        if(t.checked && t.libele == this.userHeaders.totalWCI)
          tabUsers.push(user.totalWCI)

        if(t.checked && t.libele == this.userHeaders.totalPCI)
          tabUsers.push(user.totalPCI)
        
        if(t.checked && t.libele == this.userHeaders.totalMCI)
          tabUsers.push(user.totalMCI)

        if(t.checked && t.libele == this.userHeaders.totalRMCI)
          tabUsers.push(user.totalRMCI)

        if(t.checked && t.libele == this.userHeaders.totalRPCI)
          tabUsers.push(user.totalRPCI)

        if(t.checked && t.libele == this.userHeaders.totalRWCI)
          tabUsers.push(user.totalRWCI)
      })
     
      sheetRow.push(...[
        tabUsers
      ])
    }

    if(sheetHeader.length > 0) {
      temp.push(...[
        sheetHeader
      ])
    }
    temp.push(...sheetRow)
  
    let _now = moment(new Date()).format('YYYY-MM-DDTHH:mm');
    let exportFile = new ExportFile()
    switch (type) {
      case TypeFile.EXCEL:
        exportFile.setSheetName(_now)
        exportFile.setFileName("Users_"+_now + ".xlsx")
        exportFile.setData(temp)
        
        exportFile.toExcel()
        break;
      case TypeFile.CSV:
        exportFile.setSheetName("Sheet1")
        exportFile.setFileName("User_"+_now + ".csv")
        exportFile.setData(temp)
        exportFile.toCsv()
        break;

      case TypeFile.JSON:
      
        break;
      default:
        exportFile.setSheetName("Sheet1")
        exportFile.setFileName("transactions_"+_now + ".xlsx")
        exportFile.setData(temp)
        exportFile.toExcel()
        break;
    }

    // const ws: XLSX.WorkSheet=XLSX.utils.aoa_to_sheet(temp);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // XLSX.utils.sheet_to_csv(wb.Sheets[wb.SheetNames[0]], { FS: ";", strip: true });
    
    // /* save to file */
    // XLSX.writeFile(wb, 'transactions.xlsx');
    
  }

  // More-Checkbox
  setChecked(index: number, checked: boolean) {
    this.tasks[index].checked = checked;
  }
}
