import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cpropre-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  args: string;
  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.args = this.getUrlSettingparams();
  }

  /// Recupere le parametre GET de projet
  public getUrlSettingparams(): string {
    return this.route.snapshot.params['args'];
  }

}
