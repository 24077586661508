import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IResponse } from 'src/app/api/models/response.interface';
import { User } from 'src/app/api/resources/user_resource';
import { UserService } from 'src/app/services/user.service';
import { IMenu } from 'src/app/utils/utils';

@Component({
  selector: 'cpropre-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {

  menus: IMenu[] = []
  currentUser: User;
  response: IResponse;
  
  constructor(
    private userService: UserService,
    private router:Router
    ) { 
      console.log(this.userService.getCurrentUser())
    }

  ngOnInit() {
    this.userService.loadCurrentUser().then((data)=> {
      this.response = data;
      if(!this.response.error) {
        let _currentUser: User = this.response.content
        
        this.userService.init(_currentUser)
        this.menus = _currentUser.menus

        this.currentUser = this.userService.getCurrentUser()
      }
    }).catch((reason)=> {
      if (reason instanceof HttpErrorResponse) {
        if(reason.status === 401) {
          this.response = {
            error:  true,
            statusCode: reason.status,
            version: "v1",
            message: reason.statusText,
            content: ""
          }
        }
        else {
          this.response = {
            error:  true,
            statusCode: reason.status,
            version: "v1",
            message: "An error is detected please <a href=''>Login</a>",
            content: ""
          }
        } 
      }
    });
  }
}
