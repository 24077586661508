import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { QrcodeLogger } from '../api/entities/qrcodelogger.entity';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { IUserPayload } from './payload/user.payload';
import { INumberToGeneratePayload } from './payload/qrcode-logger.payload';
import { IResources } from '../api/resources/api-resources';
import { IResponse } from '../api/models/response.interface';
import { AuthService } from './auth.service';
import { LocalStorageService } from 'ngx-webstorage';
import { Router } from '@angular/router';
import { CURRENT_USER, IMenu, IUserAction } from '../utils/utils';
import { TokenHelperService } from './tokenHelper.service';
import { User } from '../api/resources/user_resource';


@Injectable()
export class UserService{
  
  loggerSavingStatusUpdates: Subject<boolean> = new Subject<boolean>();
  loggerLoadingStatusUpdates: Subject<boolean> = new Subject<boolean>();
  qrcodeloggers: ResourceModel<QrcodeLogger[]> = null;
  private ngxApiResources: IResources;
  currentUser: User;

  constructor(
    private _ngxApiResources: NgxApiResources,
    private userService: AuthService,
    private storage: LocalStorageService,
    private tokenHelperService: TokenHelperService
    ) {
    this.ngxApiResources = this._ngxApiResources.loadResources;

  }

   /**
   * This method is used in the main app component to load an active user during the bootstrap process
   */
    public init(currentUser: User) {
      this.setCurrentUser(currentUser)
      //this.storage.store(CURRENT_USER, serverData.user);
      // if (this.tokenHelperService.getToken() != null) {
      //   this.loadCurrentUser();
      // }
    }

  public createUser(userPayload: IUserPayload){
    let promise = this.ngxApiResources.userResource._createUser({}, userPayload).$promise;
    return promise;
  }

  public updateUser(userPayload: IUserPayload,ref: string){
    let promise = this.ngxApiResources.userResource._updateUser({ref}, userPayload).$promise;
    return promise;
  }

  public loadCurrentUser() {
    let promise = this.ngxApiResources.userResource._currentUser().$promise;
    return promise;
  }

  //Get List User
  public getListUser() {
    let promise = this.ngxApiResources.userResource._getListUsers().$promise;
    return promise;
  }

  public updateStatusUser(ref: string,status: boolean) {
    let promise = this.ngxApiResources.userResource._updateStatusUser({ref: ref, status: status ? 1 : 0 }, {status}).$promise;
    return promise;
  }

  public loadStatsUsers(dateRange: Object) {
    let promise = this.ngxApiResources.userResource._loadStatUsers(dateRange).$promise;
    return promise;
  }

  private setCurrentUser(user: User) {
    this.currentUser = user;
  }
  public getCurrentUser(): User {
    //return this.storage.retrieve(CURRENT_USER) as User;
    return this.currentUser;
  }

  public getCurrentUserMenus(): IMenu[] {
    return this.currentUser.menus;
  }

  public getCurrentUserActions(): IUserAction {
    return this.currentUser.actions
  } 

  public loadQrcodeLogger(){
    let promise = this.ngxApiResources.userResource._loadQrcodeLogger().$promise;
    promise.then((data) => {
      console.log(data)
      if(!data["error"]){
        this.qrcodeloggers = data["data"]
      }
      
      this.loggerLoadingStatusUpdates.next(true);
    })
    .catch((reason) => {
      // console.log('Cannot authenticate!');
      console.log(reason);
      this.loggerLoadingStatusUpdates.next(false);
    });
    return promise;
  }

  createQrcodeLogger(nbreToGenerate: INumberToGeneratePayload){
    let promise = this.ngxApiResources.userResource._createQrcodeRef(nbreToGenerate).$promise;
    promise.then((data) => {
      console.log(data)
      this.loggerSavingStatusUpdates.next(true);
    })
    .catch((reason) => {
      // console.log('Cannot authenticate!');
      console.log(reason);
      this.loggerSavingStatusUpdates.next(false);
    });
    return promise;
  }
}