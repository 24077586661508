import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';
import { IUser } from '../models/user.interface';
import { UserResource } from './user_resource';
import { TypeWasteResource } from './type_waste_resource';
import { TypeCustomerResource } from './type_customer_resource';
import { StatsResource } from './stats_resource';
import { RewardsResource } from './reward_resource';
import { LanguageResource } from './language_resource';
import { CustomerResource } from './customer_resource';
import { CityResource } from './cities_resource';

// ALL RESOURCES
export interface IResources {
    userResource: UserResource,
    typeWasteResource: TypeWasteResource,
    typeCustomerResource: TypeCustomerResource,
    statsResource: StatsResource,
    rewardsResource: RewardsResource,
    languageResource: LanguageResource,
    customerResource: CustomerResource
    cityResource: CityResource
}

@Injectable({
    providedIn: 'root'
})
export class ApiResources{

    constructor(
      private userResource: UserResource,
      private typeWasteResource: TypeWasteResource,
      private typeCustomerResource: TypeCustomerResource,
      private statsResource: StatsResource,
      private rewardsResource: RewardsResource,
      private languageResource: LanguageResource,
      private customerResource: CustomerResource,
      private cityResource: CityResource  ){

    }

    public getResources(): IResources{
      return {
        userResource: this.userResource,
        typeWasteResource: this.typeWasteResource,
        typeCustomerResource: this.typeCustomerResource,
        statsResource: this.statsResource,
        rewardsResource: this.rewardsResource,
        languageResource: this.languageResource,
        customerResource: this.customerResource,
        cityResource: this.cityResource

      }
    }
}