import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { TypeWasteService } from 'src/app/services/typewaste.service';
import { ITypeWastePayload } from 'src/app/services/payload/type-waste.payload';
import { IResponse } from 'src/app/api/models/response.interface';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';

@Component({
  selector: 'cpropre-list-type-waste',
  templateUrl: './list-type-waste.component.html',
  styleUrls: ['./list-type-waste.component.scss']
})
export class ListTypeWasteComponent implements OnInit {

  openAlert: boolean = false;
  openForm: boolean = false;
  @Input() listTypeWaste: ResourceModel<TypeWaste[]>;
  @Output() onDataUpdateWaste = new EventEmitter<boolean>();

  constructor( private typeWasteService: TypeWasteService) { }

  ngOnInit() {
    //this._loadListTypeWaste();
  }

  onSaveTypeWaste(typeWastePayload: ITypeWastePayload){
    this.typeWasteService.addTypeWaste(typeWastePayload).then((data)=>{
      let response: IResponse = data
      if(!response.error){
        this.openAlert = true;
        this.onDataUpdateWaste.emit(true);
      }
    }).catch((error)=> {

    });
  }

  onOpenForm(){
    this.openForm = !this.openForm;
  }
}
