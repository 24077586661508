import { Component, OnInit } from '@angular/core';
import {ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cpropre-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {
  args: string;
  router: ActivatedRoute;
  constructor(private route: ActivatedRoute) { 
    this.router = this.route;
  }

  ngOnInit() {
    this.args = this.geUrlparamsProducts();
  }

  /// Recupere le parametre GET de projet
  public geUrlparamsProducts(): string {
    return this.route.snapshot.params['args'];
  }
}
