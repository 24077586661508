import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { map2Obj } from 'src/app/utils/utils';
declare const $: any;

@Component({
  selector: 'sesa-daterangepicker-material',
  templateUrl: './daterangepicker-material.component.html',
  styleUrls: ['./daterangepicker-material.component.scss']
})
export class DaterangepickerMaterialComponent implements OnInit, AfterViewInit, OnChanges {

  @Output() dateRange: EventEmitter<Object> = new EventEmitter<Object>();
  
  start = moment().subtract(29, 'days');
  end = moment();

  @Input() startdate;
  @Input() range: string;

  constructor() { }

  ngOnInit() {
    
    
  }

  ngAfterViewInit (){
    
    $(".daterange").click(function(){
      $(".daterange").removeClass("checked")
      $(this).addClass("checked")
    })

    $('.daterange').daterangepicker({
      startDate: this.start,
      endDate: this.end,
      ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],
          'Last 30 Days': [moment().subtract(29, 'days'), moment()],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
      }
    },(start: moment.Moment, end: moment.Moment)=> {
      
      $('.daterange.checked span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      this.range = start.format('MMMM D, YYYY');
      $('.daterange.checked .startdate').val(start.format('YYYY-MM-DD'))
      $('.daterange.checked .enddate').val(end.format('YYYY-MM-DD'))
      //this.run()
    });

    this.initDateRange(this.start, this.end);
    
    $('.daterange').on('apply.daterangepicker', function(ev, picker) {
      
    });
  }

  ngOnChanges() {
    
  }

  private initDateRange(start: moment.Moment, end: moment.Moment) {
    //console.log({startdate: start.format('YYYY-MM-DD'), enddate: end.format('YYYY-MM-DD')})
    $('.daterange.checked span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    this.range = start.format('MMMM D, YYYY');

    $('.daterange.checked .startdate').val(start.format('YYYY-MM-DD'))
    $('.daterange.checked .enddate').val(end.format('YYYY-MM-DD'))
    this.run()
  }
    
  clear(): void {
    
  }

  eventClicked(e): void {
    //console.log({'eventClicked()': e});
  }

  run() {
    
    let params: Map<string, string> = new Map();

    let startDate =  $('.daterange.checked .startdate').val()
    let endDate = $('.daterange.checked .enddate').val()

    params.set("startdate", startDate);
    params.set("enddate", endDate);
    this.dateRange.emit(map2Obj(params));
  }

}
