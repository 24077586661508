import { ResourceActionHttpMethod } from 'ngx-resource-factory/resource/resource-action-http-method';

export var Apiroutes = {
    //USER
    authenticateUrl: {
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: false,
        urlSuffix: '/w/auth/login',
      },

    logoutUrl:{
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/logout/',
      },

    currentUserUrl:{
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: false,
        urlSuffix: '/w/user/current/',
      },

    createUserUrl:{
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/user/create'
      },
    updateUserUrl: {
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/user/update/:ref'
    },
    listUsersUrl: {
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/users',
    },

    statusUsersUrl: {
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/user/:ref',
    },

    statUsersUrl: {
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/getUsersStats',
    },

    // STATS
    poolUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/pool/',
    },
    topStatUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/topStat/',
    },

    //CUSTOMER
    listCustomerUrl: {
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/crm/customers/',
      },

    statsOnPointsUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/getStatsOnPoints/'
    },
    addTypeCustomerUrl: {
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/addTypeCustomer/',
      },
    listTypeCustomerUrl: {
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/getListTypeCustomers/',
      },
    updateTypeCustomerUrl: {
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/typeCustomer/modify/:ref',
    },
    toggleEnableTypeCustomerUrl: {
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/typeCustomer/toggle-enable/:ref',
    },
    updateOrderTypeCustomerUrl:{
      method: ResourceActionHttpMethod.POST,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/typeCustomer/order',
    },

    // TYPE WASTE
    addTypeWasteUrl: {
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/typeWaste/',
      },

    updateTypeWasteUrl: {
        method: ResourceActionHttpMethod.PUT,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/typeWaste/modify/:ref',
      },

    toggleEnableTypeWasteUrl: {
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/typeWaste/toggle-enable/:ref',
    },

    listTypeWasteUrl:{
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/getTypeWastes/',
      },

    deleteTypeWasteUrl:{
        method: ResourceActionHttpMethod.DELETE,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/typeWaste/delete/:ref',
      },
    updateOrderTypeWasteUrl:{
      method: ResourceActionHttpMethod.POST,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/typeWaste/order',
    },

    statsTypeWasteUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/getWasteStatistique/',
    },



    // REWARD
    listRewardUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/rewards',
    },

    addRewardUrl: {
        method: ResourceActionHttpMethod.POST,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/addreward',
      },

    deleteRewardUrl:{
        method: ResourceActionHttpMethod.DELETE,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/w/reward/:ref',
      },
    updateRewardUrl:{
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/reward/modify/:ref',
    },
    toggleEnableRewardUrl:{
      method: ResourceActionHttpMethod.PUT,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/reward/toggle-enable/:ref',
    },
    statsRewardUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/getRewardStatistique/',
    },

    statsTop10TranDetailOntimeUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/stats/getTop10TranDetailOntime/',
    },

    updateOrderRewardUrl:{
      method: ResourceActionHttpMethod.POST,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/reward/order',
    },

    // CITIES
    citiesUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/cities/',
    },

    // TRANSACTIONS
    transactionsUrl:{
      method: ResourceActionHttpMethod.GET,
      isList: false,
      invalidateCache: true,
      withCredentials: true,
      urlSuffix: '/w/getTransactions/',
    },

    //QLRCODE
    createCustomerQrCodeRefUrl: {
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/qrcodelogger/'
      },

    loadQrCodeRefUrl: {
        method: ResourceActionHttpMethod.GET,
        isList: false,
        invalidateCache: true,
        withCredentials: true,
        urlSuffix: '/qrcodelogger/load'
      }
};
