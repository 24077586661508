import { Injectable } from "@angular/core";
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from "@angular/common/http";
import { Observable } from "rxjs";
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from "src/environments/environment.prod";
import { Apiroutes } from "../routes/api_routes";
import { JwtHelperService } from "@auth0/angular-jwt";
import { TokenHelperService } from "src/app/services/tokenHelper.service";

interface CustomResponseHeaderData {
  path: string;
  roles: string;
  token: string;
}

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private tokenHelperService: TokenHelperService
    ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    // if(!request.url.startsWith(environment.apiUrl)) {
    //   return;
    // }

    let token = this.tokenHelperService.getToken();

    // SET REQUEST HEADERS
    let _httpHeaders = {
      setHeaders: {
      },
      withCredentials: false
    };

    if (token) {
      _httpHeaders.setHeaders['Authorization'] = `Bearer ${token}`;
    }

    request = request.clone(_httpHeaders)
    //console.log("Trace inteceptor", request);

    return next.handle(request).map((event: HttpEvent<any>) => {

      if (event instanceof HttpResponse) {

        // RECUPERE LES DONNEES DU HEADER
        let headerData: CustomResponseHeaderData = {
          path: event.headers.get('X-Path'),
          roles: event.headers.get('X-Roles'),
          token: event.headers.get('X-Token')
        }

        //console.log("Trace headerData", event.headers);
        // VERIFIE SI C'EST
        if(headerData.token && (headerData.path == Apiroutes.authenticateUrl.urlSuffix)) {
          this.tokenHelperService.setToken(headerData.token)
        }
        else{
          if(this.tokenHelperService.getRoles() != headerData.roles) {
            this.authService.logout()
          }
        }
      }
      return event;
    })
    .catch((err: any, caught) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {

        }
        //console.log("ERROR Trace response", err);
        return Observable.throw(err);
      }
    });
  };

}
