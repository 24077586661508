import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { ColumnMode, SelectionType,SortType } from '@swimlane/ngx-datatable';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { TypeCustomerService } from 'src/app/services/typecustomer.service';
import { ITypeCustomerPayload } from 'src/app/services/payload/type-customer.payload';
import { IResponse } from 'src/app/api/models/response.interface';
import { Alert } from 'src/app/utils/utils';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';


@Component({
  selector: 'cpropre-list-type-customer',
  templateUrl: './list-type-customer.component.html',
  styleUrls: ['./list-type-customer.component.scss']
})
export class ListTypeCustomerComponent implements OnInit {

  openForm: boolean = false;
  @Input() rows: ResourceModel<TypeCustomer[]>;
  @Output() onDataUpdateCustomer = new EventEmitter<boolean>();

  //rows = [];
  selected = [];
  rowSelected: any;
  SortType = SortType;

  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  @Input() loadingIndicator = true;
  openAlert: Alert;

  rowActivated: boolean;

  constructor(private typeCustomerService: TypeCustomerService) { }

  ngOnInit() {
    this.openAlert = {visibility: false,message: "",backgroudColor: ""}
  }

  onSaveTypeCustomer(typeClient: ITypeCustomerPayload){
    // this.typeCustomerService.createTypeCustomer(typeClient).then((data)=>{
    //   let response: IResponse = data
    //   if(!response.error){
    //     this.onDataUpdateCustomer.emit(true);
    //     this.openAlert = {
    //       visibility: true,
    //       message: response.message,
    //       backgroudColor: "alert alert-success alert-dismissible"
    //     };
    //   }
    //   else {
    //     this.openAlert = {
    //       visibility: true,
    //       message: response.message,
    //       backgroudColor: "alert alert-danger alert-dismissible"
    //     };
    //   }
    // }).catch((error)=>{

    // });
  }

  onOpenForm(){
    this.openForm = !this.openForm;
  }

  onTypeCustomerActivate(value) {
    console.log("onTypeCustomerActivate : "+ value)
    // this.typeCustomerService.updateTypeCustomer({id: this.rowSelected['id'],status: value}).then((data)=> {
    //   let response: IResponse = data;
    //   console.log("response : " + response.content)
    //   if(!response.error) {

    //   }

    // }).catch((reason)=> {

    // });

  }

  onSelect({ selected }){
    console.log('Select Event', this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    if(event.type == 'click'){
      this.rowSelected = event.row;
      console.log(event.row);
    }
  }
}
