import { Component, OnInit,AfterViewInit, Input, Output, OnChanges } from '@angular/core';
import { operatorTheme } from './@operator-theme';
import { UserService } from 'src/app/services/user.service';
import { IUserPayload } from 'src/app/services/payload/user.payload';
import { NgxSpinnerService } from 'ngx-spinner';
import { IUser } from 'src/app/api/models/user.interface';
import { IResponse } from 'src/app/api/models/response.interface';
import { Alert, IAction, IUserAction, SubmitAction } from 'src/app/utils/utils';
import { iCheckInit } from 'src/app/@themes/icheck-active';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { User } from 'src/app/api/resources/user_resource';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { AddAgentComponent } from './add-agent/add-agent.component';
declare const $: any;
declare const swal: any;

@Component({
  selector: 'cpropre-operateur',
  templateUrl: './agent.component.html',
  styleUrls: ['./agent.component.scss']
})
export class AgentComponent implements OnInit,OnChanges, AfterViewInit {
  userSelected: User;
  countUser: number = 0;
  @Input() users: ResourceModel<User>[] = [];
  sendStatus: Alert = {
    visibility: false,
    message:"",
    backgroudColor: ""
  };
  userActions: IUserAction;
  isLoading: boolean = false;
  isEditing: boolean = false;

  status: IResponse;
  submitAction: IAction = {lib: "Register",event: SubmitAction.ADDING};

  constructor(
    private userService:UserService,
    private spinner: NgxSpinnerService,
    public dialog: MatDialog
    ) { 
      this.userActions = this.userService.getCurrentUserActions()
    }

  ngOnInit() {
    $('[data-toggle="tooltip"]').tooltip();
    this.getUsers()
  }

  ngAfterViewInit(){
    iCheckInit($);
  }

  ngOnChanges() {

  }

  openRegisterWindows(user: User) {
    const dialogRef = this.dialog.open(AddAgentComponent,{
      disableClose: true,
      data: {
        action: user ? SubmitAction.EDITING : SubmitAction.ADDING,
        payload : user 
      }
    });

    dialogRef.afterClosed().subscribe((result: { action: SubmitAction, payload: User|IUserPayload}) => {
      if(result){
        if(result.action == SubmitAction.ADDING) {
          this.onCreateUser(result.payload as IUserPayload)
        }
        if(result.action == SubmitAction.EDITING) {
          this.onUpdateUser(result.payload as IUserPayload,user.ref)
        }
      }
      else {
        console.log('Ooops')
      }
    });
  }

  getUsers() {
    this.isLoading = true
    this.userService.getListUser().then((data)=> {
      this.isLoading = false;
      let response: IResponse = data;
      if(!response.error) {
        console.log(response.content)
        this.users = response.content;
      }
    }).catch((error) => {

    })
  }

  private onCreateUser(userPayload:IUserPayload) {
    
    this.spinner.show("addUserInProgress")
    this.userService.createUser(userPayload).then((data)=> {
      let response: IResponse = data;
      this.status = {
        error: response.error,
        statusCode: response.statusCode,
        version: "v1",
        message: response.message,
        content: response.message
      }
      if(!response.error) {
        let msg = userPayload.firstname + " " + userPayload.lastname + " with role " + userPayload.plainRoles
        swal("Success!", msg, "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.getUsers();
      }
      else{
        swal({
          icon: 'error',   
          title: "Ooops !",   
          text: this.sendStatus.message,   
          type: "warning",   
          showCancelButton: false,   
          confirmButtonText: "Ok",
        })
      }
      this.spinner.hide("addUserInProgress")
      
    }).catch((err)=> {
      if (err instanceof HttpErrorResponse) {
        this.spinner.hide("addUserInProgress")

        if(err.status === 400) {
          this.status = {
            error: true,
            statusCode: 301,
            version: "v1",
            message: err.error[0].message,
            content: err.error[0].message
          }
          swal({
            icon: 'error',   
            title: "Ooops !",   
            text: err.error[0].message,   
            type: "warning",   
            showCancelButton: false,   
            confirmButtonText: "Ok",
          })
        }
        else {
          this.status = {
            error: true,
            statusCode: 301,
            version: "v1",
            message: "An error has been detected, please contact the support at support@sesa.tech",
            content: "An error has been detected, please contact the support at support@sesa.tech"
          }
        }
      }
      
    })
  }

  public onUpdateUser(userPayload:IUserPayload, ref: string) {
    this.spinner.show("addUserInProgress")
    this.userService.updateUser(userPayload,ref).then((data)=> {
      let response: IResponse = data;
      
      if(!response.error){
        this.sendStatus = {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-success alert-dismissible"
        };
        
        let msg = userPayload.firstname + " " + userPayload.lastname + " with role " + userPayload.plainRoles
        swal("Success!", msg, "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.getUsers();
      }
      else{
        this.sendStatus = {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        };
        swal({
          icon: 'error',   
          title: "Ooops !",   
          text: this.sendStatus.message,   
          type: "warning",   
          showCancelButton: false,   
          confirmButtonText: "Ok",
        })
      }
      this.spinner.hide("addUserInProgress")
      this.status = response
    }).catch((err)=> {
      console.log(err)
      if (err instanceof HttpErrorResponse) {
        this.spinner.hide("addUserInProgress")
        this.status = {
          error: true,
          statusCode: 301,
          version: "v1",
          message: "An error has been detected, please contact the support at support@sesa.tech",
          content: "An error has been detected, please contact the support at support@sesa.tech"
        }

        if(err.status === 400) {
          swal({
            icon: 'error',   
            title: "Ooops !",   
            text: "Error",   
            type: "warning",   
            showCancelButton: false,   
            confirmButtonText: "Ok",
          })
        }
      }
      
      this.sendStatus = {
        visibility: true,
        message: "An error is occured",
        backgroudColor: "alert alert-danger alert-dismissible"
      }
      
    })
  }

  public onDeleteAgent(user: User) {
    
  }

  public onUpdateStatusUser(user: User): void {
    this.userService.updateStatusUser(user.ref, !user.enabled).then((data)=> {
      let response: IResponse = data;
      if(!response.error) {
        this.getUsers();
      }
    }).catch((reason)=> {

    })
  }

  onUserToEdit(user: User) {
    this.openRegisterWindows(user)
  }
}
