import { Component,OnInit, AfterViewInit, VERSION } from '@angular/core';
import { ActiveScopeService, Scope } from './services/navigation/activeScope.service';
import { AuthService } from './services/auth.service';
import { sparklineInit } from './@themes/sparkline-active';
import { Router } from '@angular/router';
import { UserService } from './services/user.service';
declare const $: any;

export enum SNAPPETAPP {
  INIT = 'INIT',
  LOGGED = 'LOGGED',
  LOGERROR = 'LOGERROR',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewInit{
  title = 'Controle panel';

  isLoggedIn: boolean = false;
  snappedApp = SNAPPETAPP.INIT

  constructor(
    private userService: UserService,
    private activeScopeService: ActiveScopeService,
    private router:Router){
      $('nav#dropdown').meanmenu();
  }

  ngOnInit(){
    console.log("VERSION : "+VERSION.major);
     /**
     * Initialize auth for already authenticated users
     */
      //this.userService.init();
      //this.router.navigate(['dashboard']);
      
  }

  ngAfterViewInit(){
    //sparklineInit();
    
  }
}
