import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ColumnMode, SelectionType, SortType } from '@swimlane/ngx-datatable';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IResponse } from 'src/app/api/models/response.interface';
import { IUser } from 'src/app/api/models/user.interface';
import { User } from 'src/app/api/resources/user_resource';
import { FormConsole } from 'src/app/decorations/form';
import { UserService } from 'src/app/services/user.service';
import { getRoleTitle, IUserAction } from 'src/app/utils/utils';


@Component({
  selector: 'sesa-list-agent',
  templateUrl: './list-agent.component.html',
  styleUrls: ['./list-agent.component.scss']
})
export class ListAgentComponent implements OnInit, OnChanges {

  rows = [];
  temp = [];
  selected = [];
  SortType = SortType;
  ColumnMode = ColumnMode;
  SelectionType = SelectionType;
  @Input()  users: ResourceModel<User>[] = [];
  @Output() updateStatusUser = new EventEmitter<User>();
  @Output() updateUser = new EventEmitter<User>();
  @Output() userToDelete = new EventEmitter<User>();
  @Input()  isLoading: boolean;
  @Input()  isEditing: boolean;

  userActions: IUserAction;

  constructor(
    private userService: UserService,
    private spinnerService: NgxSpinnerService
    ) { 
      this.userActions = this.userService.getCurrentUserActions()
    }

  ngOnInit() {
    
  }

  ngOnChanges() {
    if(this.isEditing)
      this.spinnerService.show()
    if(!this.isEditing)
      this.spinnerService.show()
  }

  onUpdateStatusUser(user: User){
    this.updateStatusUser.emit(user)
  }

  onEdit(u: User) {
    console.log(u)
    this.updateUser.emit(u);
  }

  onDelete(u:User) {
    this.userToDelete.emit(u)
  }

  onSelect({ selected }){
    console.log('Select Event', this.selected);
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    switch (event.type) {
      case 'click':
        //this.userSelected = event.row;
        //console.log(this.userSelected);
        break;
    
      default:
        break;
    }
  }

  public getRoleTitle(userRoles: string[]) {
    return getRoleTitle(userRoles);
  }

}
