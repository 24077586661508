import { Injectable } from '@angular/core';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { IResources } from '../api/resources/api-resources';

@Injectable()
export class CityService{
  private ngxApiResources: IResources;
  constructor(private _ngxApiResources: NgxApiResources) {
    this.ngxApiResources = this._ngxApiResources.loadResources;
  }

  loadCities(){
    let promise = this.ngxApiResources.cityResource._listCities().$promise;
    return promise;
  }
}