import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';
import { IUser } from '../models/user.interface';
import { IMenu, IUserAction } from 'src/app/utils/utils';

/**
  * Class UserResource
  * @package
  * Route("")
*/

export class User extends ResourceInstance {
    ref: string;
    firstname: string;
    lastname: string;
    gender: string;
    gsm: string;
    address: string;
    city: string;
    email: string;
    photourl: string;
    roles: string[];
    enabled: boolean;
    createdat: string;
    updatedat: string;
    menus: IMenu[];
    actions: IUserAction;
    others: any;
  }


  export class StatsUser extends ResourceInstance {
    id        : string;
    ref       : string;
    firstname : string;
    lastname  : string;
    gsm       : string;
    address   : string;
    city      : string;
    email     : string;
    gender    : string;
    enabled   : string;
    createdat: string;
    updatedat: string;

    totalWCI: number;
    totalPCI: number;
    totalMCI: number;

    totalRWCI: number;
    totalRPCI: number;
    totalRMCI: number;

    totalPCO: number;
    totalWCO: number;
    totalMCO: number;
    customers : UserCustomerStat[];
  }

  export interface UserCustomerStat {
    ref: string;
    typecustomer: string;
    name  : string;
    gender    : string;
    gsm       : string;
    email     : string;
    address   : string;
    city      : string;
    area      : string;
    country   : string;
    from      : string;
    createdat : string;
    updatedat : string;
    totalWCI: number;
    totalPCI: number;
    totalMCI: number;

    totalRWCI: number;
    totalRPCI: number;
    totalRMCI: number;

    totalPCO: number;
    totalWCO: number;
    totalMCO: number;
  }

  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
    name: 'UserResource',
    url: environment.apiUrl,
    instanceClass: User,
    stripTrailingSlashes: false,
    withCredentials: false
  })

  export class UserResource extends Resource<User | StatsUser> {

      constructor(
          registry: ResourceRegistry,
          http: HttpClient) {
          super(registry, http);
      }
      @ResourceAction(Apiroutes.authenticateUrl)
      _authenticate: ResourceActionMethod<any, any, {error:boolean,token: string}>;

      @ResourceAction(Apiroutes.currentUserUrl)
      _currentUser: ResourceActionMethod<any, any, User>;

      @ResourceAction(Apiroutes.logoutUrl)
      _logoutUser: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.createUserUrl)
      _createUser: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.updateUserUrl)
      _updateUser: ResourceActionMethod<any, any, User>;

      @ResourceAction(Apiroutes.createCustomerQrCodeRefUrl)
      _createQrcodeRef: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.loadQrCodeRefUrl)
      _loadQrcodeLogger: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.listUsersUrl)
      _getListUsers: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.statusUsersUrl)
      _updateStatusUser: ResourceActionMethod<any, any, any>;

      @ResourceAction(Apiroutes.statUsersUrl)
      _loadStatUsers: ResourceActionMethod<any, any, StatsUser>;

  }
