import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { IResponse } from 'src/app/api/models/response.interface';
import { Reward } from 'src/app/api/resources/reward_resource';
import { IRewardPayload } from 'src/app/services/payload/reward.payload';
import { IAction, SubmitAction } from 'src/app/utils/utils';

@Component({
  selector: 'sesa-add-reward',
  templateUrl: './add-reward.component.html',
  styleUrls: ['./add-reward.component.scss']
})
export class AddRewardComponent implements OnInit, OnChanges {

  formGrp: FormGroup;
  @Input() reward: Reward ;
  @Output() rewardChange = new EventEmitter<Reward>();

  @Output() createEntityEvent = new EventEmitter<IRewardPayload>();
  @Output() updateEntityEvent = new EventEmitter<IRewardPayload>();
  @Input()  response: IResponse ;

  @Input() action: IAction = {lib: "Register",event: SubmitAction.ADDING};
  @Output() actionChange = new EventEmitter<IAction>();

  constructor() { }

  ngOnInit() {
    this._iniForm();
  }

  ngOnChanges() {
    if(this.response && !this.reward){
      if(!this.response.error){
        this.onReset()
      } 
    }
     
    if(this.reward) {
      this.formGrp.patchValue({
        "lib": this.reward.lib,
        "uvp": this.reward.uvp,
        "uvm": this.reward.uvm,
        "cover": this.reward.imageurl
      })
    }
  }
  _iniForm(){

    this.formGrp = new FormGroup({
      lib: new FormControl(this.reward !=null ? this.reward.lib : null, [Validators.required, Validators.pattern(  /[0-9a-zA-Z]/)], null),
      uvp: new FormControl(this.reward !=null ? this.reward.uvp : null, [Validators.required, Validators.pattern(  /(^[0-9]{0,9}$)|(^[0-9]{0,9}\.[0-9]{0,3}$)/)], null),
      uvm: new FormControl(this.reward !=null ? this.reward.uvm : null, [Validators.required, Validators.pattern(  /(^[0-9]{0,9}$)|(^[0-9]{0,9}\.[0-9]{0,3}$)/)], null),
      cover: new FormControl(this.reward !=null ? this.reward.imageurl : null, [Validators.required, Validators.pattern(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i)], null)
    });
  }

  onSubmit(){
    if(this.formGrp.invalid){
      console.log("Error sending reward data");
    }
    else {
      if(this.action.event == SubmitAction.ADDING)
        this.createEntityEvent.emit(this.formGrp.value);
      if(this.action.event == SubmitAction.EDITING) 
        this.updateEntityEvent.emit(this.formGrp.value);

      this.actionChange.emit(this.action)
    }
  }

  onReset() {
    this.formGrp.reset()
    this.reward = null;
    this.rewardChange.emit(this.reward)
    this.action = {lib: "Register", event: SubmitAction.ADDING}
  }
}
