import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';

/**
  * Class TypeCustomerResource
  * @package
*/
export class TypeCustomer extends ResourceInstance {
  ref: string;
  lib: string;
  createat: string;
  updateat: string;
  enabled: boolean;
  code: string;
}
  
@Injectable({
  providedIn: 'root'
})
@ResourceConfiguration({
    name: 'TypeCustomerResource',
    url: environment.apiUrl, //+ 'settings/:pk/',//user/
    pkAttr: 'pk',
    instanceClass: TypeCustomer,
    stripTrailingSlashes: true,
})
export class TypeCustomerResource extends Resource<TypeCustomer> {

  constructor(
    registry: ResourceRegistry,
    http: HttpClient) {
    super(registry, http);
  }

  @ResourceAction(Apiroutes.addTypeCustomerUrl)
  _addTypeCustomer: ResourceActionMethod<any, any, TypeCustomer>;

  @ResourceAction(Apiroutes.listTypeCustomerUrl)
  _listTypeCustomer: ResourceActionMethod<any, any, TypeCustomer>;

  @ResourceAction(Apiroutes.updateTypeCustomerUrl)
  _updateTypeCustomer: ResourceActionMethod<any, any, any>;

  @ResourceAction(Apiroutes.toggleEnableTypeCustomerUrl)
    _toggleEnable: ResourceActionMethod<any, any, TypeCustomer>;

  @ResourceAction(Apiroutes.updateOrderTypeCustomerUrl)
    _updateOrder: ResourceActionMethod<any, any, TypeCustomer>;

}