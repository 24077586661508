import { Component, OnInit,AfterViewInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { INumberToGeneratePayload } from 'src/app/services/payload/qrcode-logger.payload';

@Component({
  selector: 'cpropre-qrcodelogger',
  templateUrl: './qrcodelogger.component.html',
  styleUrls: ['./qrcodelogger.component.scss']
})
export class QrcodeloggerComponent implements OnInit,AfterViewInit {

  isOpenAddLoggerComponent: boolean = false;
  openAlert: boolean = false;
  constructor(private userService : UserService) { }

  ngOnInit() {
  }
  ngAfterViewInit(){
  }

  onClickAddLoggerButton(bouttonStatus: boolean){
    return this.isOpenAddLoggerComponent = bouttonStatus;
  }

  onSaveLogger(numberToGen: INumberToGeneratePayload){
    let promise = this.userService.createQrcodeLogger(numberToGen);
    this.userService.loggerSavingStatusUpdates.subscribe((value) =>{
      if(value){
        promise.then((data) =>{
          if(!data["error"]){
            this.onClickAddLoggerButton(false);
            this.openAlert = true;
          }
        })
        .catch((reason) =>{

        })
        
      }
      else{
        console.log("echec")
      }
    })
  }
}
