import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxResourceFactoryModule } from 'ngx-resource-factory';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MonacoEditorModule } from 'ngx-monaco-editor';
import { NgPipesModule } from 'ngx-pipes';
import { ToastrModule } from "ngx-toastr";
import { NgxAnxFormsModule } from "ngx-anx-forms";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {A11yModule} from '@angular/cdk/a11y';
// import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {OverlayModule} from '@angular/cdk/overlay';

import {DataTablesModule} from 'angular-datatables';
import {LocalStorageService, NgxWebstorageModule} from 'ngx-webstorage';
//import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { AppRoutingModule } from './app-routing.module';
import { ServicesModule } from './services/services.module';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

//import { MultiInputModule } from "@ng-libs/multi-input-array";
import { QRCodeModule } from 'angularx-qrcode'; // permet d'utiliser la balise qrcode dans la vue
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer'; // <-- Import PdfJsViewerModule module
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgxSpinnerModule } from "ngx-spinner";
//import { NgxDateRangeModule } from 'ngx-daterange';
//import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

import { AppComponent } from './app.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoginComponent } from './components/login/login.component';
import { BaseComponent } from './layout/base/base.component';
import { DashboardComponent } from './layout/pages/dashboard/dashboard.component';
import { CrmComponent } from './layout/pages/crm/crm.component';
import { CustomerComponent } from './layout/pages/customer/customer.component';
import { AgentComponent } from './layout/pages/agent/agent.component';
import { SigninComponent } from './components/login/signin/signin.component';
import { RegisterComponent } from './components/login/register/register.component';
import { AddAgentComponent } from './layout/pages/agent/add-agent/add-agent.component';
import { ListAgentComponent } from './layout/pages/agent/list-agent/list-agent.component';
import { AlertComponent } from './components/alert/alert.component';
import { SettingsComponent } from './layout/pages/settings/settings.component';
import { QrcodeloggerComponent } from './layout/pages/settings/qrcodelogger/qrcodelogger.component';
import { AddLoggerComponent } from './layout/pages/settings/qrcodelogger/add-logger/add-logger.component';
import { ListLoggerComponent } from './layout/pages/settings/qrcodelogger/list-logger/list-logger.component';
import { GeneratepdfComponent } from './components/generatepdf/generatepdf.component';
import { PreconfigComponent } from './layout/pages/settings/preconfig/preconfig.component';
import { ListTypeWasteComponent } from './layout/pages/settings/preconfig/list-type-waste/list-type-waste.component';
import { AddTypeWasteComponent } from './components/add-type-waste/add-type-waste.component';
import { ListTypeCustomerComponent } from './layout/pages/settings/preconfig/list-type-customer/list-type-customer.component';
import { DetailsCustomerComponent } from './layout/pages/customer/details-customer/details-customer.component';
import { JwtInterceptor } from './api/helpers/jwt.interceptor';
import { LoadingScreenInterceptor } from './api/helpers/loading.interceptor';
import { NgxApiModule } from './api/ngx-api.module';
import { AddRewardComponent } from './components/add-reward/add-reward.component';
import { ChartsModule } from 'ng2-charts';
import { ToggleComponent } from './components/toggle/toggle.component';
import { DatepickerComponent } from './components/datepicker/datepicker.component';
import { DaterangepickerMaterialComponent } from './components/daterangepicker-material/daterangepicker-material.component';
import { DaterangepickerComponent } from './components/daterangepicker/daterangepicker.component';
import { WastesComponent } from './layout/pages/wastes/wastes.component';
import { RewardsComponent } from './layout/pages/rewards/rewards.component';
import { CitiesComponent } from './layout/pages/cities/cities.component';
import { TransactionsComponent } from './layout/pages/transactions/transactions.component';
import { TypeCustomerComponent } from './layout/pages/type-customer/type-customer.component';
import { AddTypeCustomerComponent } from './layout/pages/type-customer/add-type-customer/add-type-customer.component';
import { JwtModule } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';
import { Apiroutes } from './api/routes/api_routes';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NavbarComponent,
    LoadingScreenComponent,
    LoginComponent,
    BaseComponent,
    DashboardComponent,
    CrmComponent,
    CustomerComponent,
    AgentComponent,
    SigninComponent,
    RegisterComponent,
    AddAgentComponent,
    ListAgentComponent,
    AlertComponent,
    SettingsComponent,
    QrcodeloggerComponent,
    AddLoggerComponent,
    ListLoggerComponent,
    GeneratepdfComponent,
    PreconfigComponent,
    ListTypeWasteComponent,
    AddTypeWasteComponent,
    ListTypeCustomerComponent,
    AddTypeCustomerComponent,
    DetailsCustomerComponent,
    AddRewardComponent,
    ToggleComponent,
    DatepickerComponent,
    DaterangepickerMaterialComponent,
    DaterangepickerComponent,
    WastesComponent,
    RewardsComponent,
    CitiesComponent,
    TransactionsComponent,
    TypeCustomerComponent
    
  ],
  entryComponents: [
    AddAgentComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxResourceFactoryModule.forRoot(),
    NgbModule.forRoot(),
    FontAwesomeModule,
    PerfectScrollbarModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MonacoEditorModule.forRoot(),
    NgPipesModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxAnxFormsModule.forRoot(),
    ServicesModule.forRoot(),
    NgxApiModule.forRoot(),
    //MultiInputModule,
    QRCodeModule,
    PdfJsViewerModule,
    NgxLoadingModule.forRoot({}),
    NgxDatatableModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    ChartsModule,
    MatSliderModule,
    DataTablesModule,
    NgxWebstorageModule.forRoot(),
    //NgxDateRangeModule,
    //NgxDaterangepickerMd.forRoot({
    //  separator: ' - ',
    //  applyLabel: 'Okay'
    //})
    //SweetAlert2Module.forRoot()
    A11yModule,
    // ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule
    // JwtModule.forRoot({
    //   config: {
    //     tokenGetter: ()=> {
    //       console.log("localStorage: " + localStorage.getItem(AUTH_TOKEN));
    //       return localStorage.getItem(AUTH_TOKEN)
    //     },
    //     throwNoTokenError: true,
    //     authScheme: "Bearer ",
    //     allowedDomains: ["api.sesa.dev"],
    //     disallowedRoutes: [environment.apiUrl+Apiroutes.authenticateUrl]
    //   },
    // }),
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
  bootstrap: [AppComponent]
})
export class AppModule { }
