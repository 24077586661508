import { Component, OnInit, OnChanges, Input, Output, EventEmitter,VERSION } from '@angular/core';
import * as moment from 'moment';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { Subject } from 'rxjs';
import { Customer } from 'src/app/api/resources/customer_resource';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';
import { User } from 'src/app/api/resources/user_resource';
import { map2Obj } from 'src/app/utils/utils';

//import * as $ from 'jquery';
//declare var jquery:any;
declare const $: any;


@Component({
  selector: 'sesa-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  styleUrls: ['./daterangepicker.component.scss']
})
export class DaterangepickerComponent implements OnInit, OnChanges {

  @Output() dateRange = new EventEmitter<Object>();
  
  start = moment().subtract(29, 'days');
  end = moment();

  @Input() range: string;
  @Input() users: User[] = [];
  @Input() typeCustomers: ResourceModel<TypeCustomer>[] = [];
  @Input() typeWastes : ResourceModel<TypeWaste>[] = [];

  constructor() {

   }

  ngOnInit() {
    
    $('.selecttypecustomer').selectpicker();
    $('.selectuser').selectpicker();
    $('.selecttypeWaste').selectpicker();
    
    $('#reportrange').daterangepicker({
        startDate: this.start,
        endDate: this.end,
        ranges: {
            'Today': [moment(), moment()],
            'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Last 7 Days': [moment().subtract(6, 'days'), moment()],
            'Last 30 Days': [moment().subtract(29, 'days'), moment()],
            'This Month': [moment().startOf('month'), moment().endOf('month')],
            'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        }

    },(start: moment.Moment, end: moment.Moment)=>{
      $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
      this.range = start.format('MMMM D, YYYY');

      $('#startdate').val(start.format('YYYY-MM-DD'))
      $('#enddate').val(end.format('YYYY-MM-DD'))
      //this.run()
    }); //this.cb

    this.initDateRange(this.start, this.end);
  
    $('.selectuser').click(function () {
      $('.selectuser').selectpicker('refresh');
    });

    $('.selecttypecustomer').click(function () {
      $('.selecttypecustomer').selectpicker('refresh');
    });

    $('.selecttypeWaste').click(function () {
      $('.selecttypeWaste').selectpicker('refresh');
    });
  }

  ngOnChanges() {

  }

  public initDateRange(start: moment.Moment, end: moment.Moment) {
    //console.log({startdate: start.format('YYYY-MM-DD'), enddate: end.format('YYYY-MM-DD')})
    $('#reportrange span').html(start.format('MMMM D, YYYY') + ' - ' + end.format('MMMM D, YYYY'));
    this.range = start.format('MMMM D, YYYY');

    $('#startdate').val(start.format('YYYY-MM-DD'))
    $('#enddate').val(end.format('YYYY-MM-DD'))

    this.run()
    
  }

  run() {
    let params: Map<string, string> = new Map();

    let startDate =  $('#startdate').val()
    let endDate = $('#enddate').val()
    let user = $('.selectuser option:selected').val()
    let typeCustomer = $('.selecttypecustomer option:selected').val()
    let typeWaste = $('.selecttypeWaste option:selected').val()

    if(user != 'default')
      params.set("user", user);

    if(typeCustomer != 'default')
      params.set("typecustomer", typeCustomer);

    if(typeWaste != 'default')
      params.set("typewaste", typeWaste);

    params.set("startdate", startDate);
    params.set("enddate", endDate);
    
    this.dateRange.emit(map2Obj(params));
  }
  
}

