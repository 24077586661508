import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LocalStorageService } from "ngx-webstorage";
import { AUTH_TOKEN } from "../utils/utils";

@Injectable()
export class TokenHelperService {

    protected token;
    protected jwtHelper;
    protected jwtDecode;

    constructor(
        private storage: LocalStorageService
    ) {
        this.token = this.storage.retrieve(AUTH_TOKEN);
        this.jwtHelper = new JwtHelperService();
        this.jwtDecode = this.jwtHelper.decodeToken(this.token);
    }
    
    // Set token on local storage
    public setToken(token: string) {
        this.storage.store(AUTH_TOKEN, token);
        this.token = this.storage.retrieve(AUTH_TOKEN);
        this.jwtDecode = this.jwtHelper.decodeToken(this.token);
    }

    // Get local token
    public getToken() {
        return this.token;
    }

    // Remove token on local
    public removeToken() {
        this.storage.clear(AUTH_TOKEN);
    }

    // Get data
    public getRoles(): string {
        return this.jwtDecode ? this.jwtDecode['roles'] : ""
    }

    public isExpired(): boolean {
        return this.jwtHelper.isTokenExpired(this.token);
    }
}