import { Component, OnInit } from '@angular/core';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IResponse } from 'src/app/api/models/response.interface';
import { Reward } from 'src/app/api/resources/reward_resource';
import { IRewardPayload } from 'src/app/services/payload/reward.payload';
import { RewardService } from 'src/app/services/reward.service';
import { Alert, IAction, IUserAction, map2Obj, SubmitAction } from 'src/app/utils/utils';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { UserService } from 'src/app/services/user.service';

declare const $: any;
declare const swal: any;

@Component({
  selector: 'sesa-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss']
})
export class RewardsComponent implements OnInit {
  userActions: IUserAction;
  errorAddReward: string;
  listRewards: ResourceModel<Reward>[] = [];
  rewardSelected: Reward;
  flashBag: Alert;
  isUpdateOrderOn=false;
  submitAction: IAction = {lib: "Register",event: SubmitAction.ADDING};
  status: IResponse;
  isLoading: boolean = false;

  constructor(
    private rewardService: RewardService,
    private spinner: NgxSpinnerService,
    private userService: UserService
  ) { 
    this.userActions = this.userService.getCurrentUserActions()
  }

  ngOnInit() {
    this.flashBag = {visibility: false,message: "",backgroudColor: ""}
    this.loadRewards();
  }

  private loadRewards() {
    this.isLoading = true
    this.rewardService.loadRewards().then((data)=> {
      this.isLoading = false
      let response: IResponse = data;
      if(!response.error) {
        this.listRewards = response.content;
      }
    }).catch((reason)=> {

    })
  }

  onCreateReward(rewardPayload: IRewardPayload) {
    this.spinner.show()
    let promise = this.rewardService.addReward(rewardPayload);
    

    promise.then((data) => {
      console.log(data)
      this.spinner.hide()
      let response: IResponse = data;

      if(!response.error) {
        this.flashBag =  { visibility: true, message: response.message,backgroudColor: "alert alert-success alert-dismissible"}
        swal("Success!", "Waste successfuly has been created", "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.loadRewards();
      }
      else {
        this.errorAddReward = response.message
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        }
      }
      this.status = response
    })
    .catch((reason) => {
      this.spinner.hide()
      this.status = {
        error: true,
        statusCode: 301,
        version: "v1",
        message: "An error has been detected, please contact the support at support@sesa.tech",
        content: "An error has been detected, please contact the support at support@sesa.tech"
      }
      swal({
        icon: 'error',   
        title: "Ooops !",   
        text: this.flashBag.message,   
        type: "warning",   
        showCancelButton: false,   
        confirmButtonText: "Ok",
      })
    });
    
  }

  onUpdateReward(rewardPayload: IRewardPayload) {
    this.spinner.show()
    let promise = this.rewardService.update(this.rewardSelected.ref,rewardPayload);
    promise.then((data) => {
      console.log(data)
      this.spinner.hide()
      let response: IResponse = data;

      if(!response.error) {
        this.flashBag =  { visibility: true, message: response.message,backgroudColor: "alert alert-success alert-dismissible"}
        swal("Success!", "Waste successfuly has been updated", "success")
        this.submitAction = {lib: "Register",event: SubmitAction.ADDING};
        this.loadRewards();
      }
      else {
        this.errorAddReward = response.message
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        }
      }
      this.status = response
    })
    .catch((reason) => {
      this.spinner.hide()
      this.status = {
        error: true,
        statusCode: 301,
        version: "v1",
        message: "An error has been detected, please contact the support at support@sesa.tech",
        content: "An error has been detected, please contact the support at support@sesa.tech"
      }
      swal({
        icon: 'error',   
        title: "Ooops !",   
        text: this.flashBag.message,   
        type: "warning",   
        showCancelButton: false,   
        confirmButtonText: "Ok",
      })
    });
  }

  onRewardAlertDialogue(ref: string) {
    swal({
			title: "Are you sure?",
			text: "You will not be able!",
			type: "warning",
			showCancelButton: true,
			confirmButtonText: "Yes, delete it!",
		}).then((data)=>{
      console.log(data)
      this.rewardService.remove(ref).then((data)=> {
        this.loadRewards();
        swal("Deleted!", "Your imaginary file has been deleted.", "success");
      }).catch((reason)=> {
        console.log(reason);
        swal("Oops!", "An error is detected.", "success");
      })

    });
  }

  onRewardSelected(t) {
    this.rewardSelected = t;
    this.submitAction = {lib: "Update", event: SubmitAction.EDITING}
  }

  onToggleEnabled(reward: Reward) {
      this.rewardService.toggleEnable(reward.ref).then((data)=> {
      let response: IResponse = data;

      if(!response.error) {
        this.loadRewards()
      }
    }).catch((reason)=> {
      console.log(reason)
    })
  }

  // LIST DRAG AND DROP
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.listRewards, event.previousIndex, event.currentIndex);
    this.isUpdateOrderOn=true;
  }

  public updateOrder() {
    if(this.isUpdateOrderOn) {
      let params: Map<number, string> = new Map();
      this.listRewards.forEach((typseWaste,i) =>{
        params.set(i,typseWaste.ref);
      })
      console.log(params)
      this.spinner.show("sp-order");
      this.rewardService.updateOrder(map2Obj(params)).then((data)=> {
        let response: IResponse = data;
        if(!response.error){
          console.log(response.message)
        }
        else {
          console.log(response.message)
        }
        this.spinner.hide("sp-order");
        this.isUpdateOrderOn = false;
      }).catch((reason)=> {
        this.isUpdateOrderOn = false;
        console.log(reason)
      })
    }
  }
}
