import { Component, OnInit,Input,ViewChild } from '@angular/core';
import * as jsPDF from 'jspdf';  
import QRCode from 'qrcodejs2';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'cpropre-generatepdf',
  templateUrl: './generatepdf.component.html',
  styleUrls: ['./generatepdf.component.scss']
})
export class GeneratepdfComponent implements OnInit {

  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;
  @ViewChild('pdfViewerAutoLoad') pdfViewerAutoLoad;

  readonly QX: number = 15;
  readonly QY: number= 30;
  readonly QW: number = 70;
  readonly QH: number = 70;
  x: number;
  y: number;

  @Input()
  qrcodeLoggerSelectedList : string [] = [];
  
  constructor(private userService: UserService) { }

  ngOnInit() {
    
  }

  private getQx(){
      return this.x;
  }

  private setQx(x){
      this.x = x;
  }

  private getQy(){
      return this.y;
  }

  private setQy(y){
      this.y = y;
  }


  public onGeneratePdfOfQRCode(): void{
    console.log(this.qrcodeLoggerSelectedList)
    this.openPdf();
    
  }

  private openPdf() {
    
    let pdfUnit8Array = this.generateQRCode2Pdf(this.qrcodeLoggerSelectedList);
    if(pdfUnit8Array == null)
      return ;

    this.pdfViewerOnDemand.pdfSrc = pdfUnit8Array;
    this.pdfViewerOnDemand.refresh();
    
  }

  private generateQRCode2Pdf(qrcodeDatas: string[] ){
    
    if(qrcodeDatas.length == 0)
      return;
    
    var pdf = new jsPDF('', 'pt', 'a4') //Initialize pdf
    var elQrCodeUrl = document.getElementById('qrCodeUrl');

    this.setQx(this.QX);
    this.setQy(this.QY);
    let nbline = 1;
    let k = 0;
    let counter = 0;

    for(let i=0; i< qrcodeDatas.length; i++){
        let pos = i +1
        counter++;
        var canvasDiv = document.createElement("div"); //New dom element, if there is only one page, you can use the page element directly
        canvasDiv.id = 'canvas'+i
        elQrCodeUrl.append(canvasDiv)

        var qrcode = new QRCode('canvas'+i, { //qrcode generates QR code
            text: qrcodeDatas[i],
            width: 255,
            height: 255,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.M
          })
        let canvas = document.getElementById('canvas'+i).querySelector('canvas') //Get the canvas element
        //var img = document.createElement("img");
        //img.src="assets/img/logo/favicon.png"
        //img.id = "img"+i
        //document.getElementById('canvas'+i).append(img)

        //let imgSelector = document.getElementById('canvas'+i).querySelector('#img'+i)
        
        var dataURL = canvas.toDataURL(); 
        
        canvas = null; 
        pdf.addImage(dataURL, 'JPEG', this.getQx(), this.getQy(), this.QW, this.QW); //pdf to add image
        //pdf.addImage(imgSelector, 'png', 2*this.getQx(), 2*this.getQy());
        //pdf.text("hello",this.getQx()+10,this.QW,{align: "center"})
        this.setQx(this.getQx() + this.QW+5); //+5 La marge
        
        if(pos % 7 == 0){
            this.setQx(this.QX);
            this.setQy(this.getQy() + this.QH+10);
            nbline ++;
        }

        if(nbline == 8){
            pdf.addPage();
            this.setQx(this.QX); // On remet la valeur initiale
            this.setQy(this.QY); // On remet la valeur initiale
            nbline = 1;
        }  
    }//endfor
    
    

    //return pdf.output("blob");//
    pdf.save('QRcode.pdf'); 

  }
}
