import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
 
 
@Injectable()
export class AuthGuardService implements CanActivate {
    isLoggedIn: boolean = false;

    constructor(private _router:Router,private authService: AuthService ) {
        
    }
 
    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): boolean {
 
        //check some condition  
        if (!this.authService.isUserLoggedIn())  {
            this._router.navigate(["login"],{ queryParams: { retUrl: route.url} });
            return false;
        } 
        return true;
    }
 
}