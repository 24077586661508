import * as XLSX from 'xlsx';

export const CURRENT_USER = 'auth.user';
export const AUTH_TOKEN = 'auth.token';
export const ACCESS_ROLES = "auth.roles";

export interface IUserAction {
  canAddUser: boolean;
  canEditUser: boolean;
  canDeleteUser: boolean
  canOnOfUser: boolean;
  canListUsers: boolean;
  canEditRoles: boolean;
  canSeeSuperAdminCheckbox: boolean;

  canAddWaste: boolean;
  canEditWaste: boolean;
  canDeleteWaste: boolean;
  canOnOffWaste: boolean;
  canOrderWaste: boolean

  canAddReward: boolean;
  canEditReward: boolean;
  canDeleteReward: boolean;
  canOnOffReward: boolean;
  canOrderReward: boolean
  
  canAddTypeCustomer: boolean;
  canEditTypeCustomer: boolean;
  canDeleteTypeCustomer: boolean;
  canOnOffTypeCustomer: boolean;
  canOrderTypeCustomer: boolean;

}

export interface IRole {
  name: string;
  libele: string
}
export const roles: IRole[] = [
  {name: "ROLE_AGENT", libele: "Agent" },
  { name: "ROLE_SUPERV", libele: "Supervisor" },
  { name: "ROLE_ADMIN", libele: "Admin" },
  { name: "ROLE_SUPER_ADMIN", libele: "Super admin" }
]

export function getRoleTitle(role:string[]) {
  let titles:string[] = []

  role.forEach((e)=>{
    let _role: IRole = roles.find((r)=> r.name === e)
    if(_role) {
      titles.push(_role.libele)
    }
  })

  return titles.join(",");
}

export interface IMenu {
  name: string;
  visibility: boolean;
  link: string;
  active: boolean,
  icon: string
}

export interface Alert{
  visibility: boolean,
  message: String,
  backgroudColor: String
}

export enum SubmitAction {
  ADDING,
  EDITING
}

export interface IAction {
  lib: string;
  event: SubmitAction
} 

export enum TypeFile {
    EXCEL,
    CSV,
    JSON
} 

// Export file
export class ExportFile {
  private ws: XLSX.WorkSheet;//const usersJson: any[] = Array.of(this.stat.transactions[0]);
  private wb: XLSX.WorkBook;

  private data: [][] = [];
  private sheetName: string = 'sheet1';
  private fileName: string = "cls";

  
  constructor() {
    this.wb = XLSX.utils.book_new()
  }

  setSheetName(name: string){
    this.sheetName = name;
  }

  setFileName(name: string) {
    this.fileName = name;
  }

  setData(data: [][]) {
    this.data = data;
  }

  public getSheetName() {
    return this.sheetName
  }

  public getFileName() {
    return this.fileName;
  }

  public toExcel() {
    this.ws = XLSX.utils.aoa_to_sheet(this.data);
    XLSX.utils.book_append_sheet(this.wb, this.ws, this.getSheetName());
    XLSX.writeFile(this.wb, this.getFileName());
  }

  public toCsv() {
    this.ws = XLSX.utils.aoa_to_sheet(this.data);
    XLSX.utils.book_append_sheet(this.wb, this.ws, this.getSheetName());
    XLSX.utils.sheet_to_csv(this.wb.Sheets[this.wb.SheetNames[0]], { FS: ";", strip: true });
    
    /* save to file */
    XLSX.writeFile(this.wb, this.getFileName());
  }

  public toJson() {

  }
}

// Map to Object
export function map2Obj(map) {
  const obj = Array.from(map.entries()).reduce((main, [key, value])=>{
    main[key] = value
    return main
  }, {})

  return obj
}

