import { Component, OnInit } from '@angular/core';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { QrcodeLogger } from 'src/app/api/entities/qrcodelogger.entity';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'cpropre-list-logger',
  templateUrl: './list-logger.component.html',
  styleUrls: ['./list-logger.component.scss']
})
export class ListLoggerComponent implements OnInit {
  currentQrCodeLoggers: ResourceModel<QrcodeLogger[]> = null;
  constructor(private userService: UserService) { }
  
  ngOnInit() {
    this.laodAllQrCodeLogger()
  }

  private laodAllQrCodeLogger(): void{
    this.userService.loadQrcodeLogger();
    this.userService.loggerLoadingStatusUpdates.subscribe((data) => {
      if(data){
        this.currentQrCodeLoggers = this.userService.qrcodeloggers;
      }
      else{

      }
    })
  }

  public selectAllQrcodeLogger(): void{
    console.log("hello");
    this.currentQrCodeLoggers.map((qrCodeLogger) =>qrCodeLogger['checked'] = true); 
    console.log(this.currentQrCodeLoggers);
  }

  public getAllQrcodeLoggerSelected(): string[]{
    let qrCodeLoggerSelectedCode :string[] = [];
    if(this.currentQrCodeLoggers == null)
      return [];
    this.currentQrCodeLoggers.forEach(qrCodeLogger => {
      if(qrCodeLogger.hasOwnProperty('checked'))
        if(qrCodeLogger['checked'])
        qrCodeLoggerSelectedCode.push(qrCodeLogger.code);
    });

    return qrCodeLoggerSelectedCode;
  }

}
