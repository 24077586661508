import { Component, OnInit,Input,ViewChild } from '@angular/core';
import { ngxLoadingAnimationTypes, NgxLoadingComponent } from 'ngx-loading';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { TypeCustomerService } from 'src/app/services/typecustomer.service';
import { TypeWasteService } from 'src/app/services/typewaste.service';
import { IResponse } from 'src/app/api/models/response.interface';
import { TypeWaste } from 'src/app/api/resources/type_waste_resource';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';

const PrimaryWhite = '#ffffff';
const SecondaryGrey = '#ccc';
const PrimaryRed = '#dd0031';
const SecondaryBlue = '#006ddd';

@Component({
  selector: 'cpropre-preconfig',
  templateUrl: './preconfig.component.html',
  styleUrls: ['./preconfig.component.scss']
})
export class PreconfigComponent implements OnInit {

  choixSousMenu: string = "TYPE_CLIENT";

  nbreTotalTypeWaste: number;
  nbreTotalTypeCustomer: number
  listTypeWaste: ResourceModel<TypeWaste[]>;
  listTypeCustomer: ResourceModel<TypeCustomer[]>;
  loading = true;
  loadingIndicator = true;

  @ViewChild('ngxLoading') ngxLoadingComponent: NgxLoadingComponent;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;
  public primaryColour = SecondaryGrey;
  public secondaryColour = SecondaryGrey;

  constructor(
    private typeCustomerService: TypeCustomerService,
    private typeWasteService: TypeWasteService
  ) { }

  ngOnInit() {
    this._loadListTypeWaste();
    this._loadListTypeCustomer();
  }

  //Load Type dechet
  _loadListTypeWaste(){
    this.typeWasteService.listTypeWaste().then((data)=>{
      let response: IResponse = data
      if(!response.error){
        this.listTypeWaste = response.content as ResourceModel<TypeWaste[]>;
        this.nbreTotalTypeWaste = this.listTypeWaste.length;
      }

    }).catch((error)=>{

    });
  }

  //Load List type client
  _loadListTypeCustomer(){
    this.typeCustomerService.listTypeCustomer().then((data)=> {
      let response: IResponse = data
      if(!response.error){
        this.listTypeCustomer = response.content as ResourceModel<TypeCustomer[]>;
        this.loadingIndicator = false;
        console.log(this.listTypeCustomer, this.listTypeCustomer.length);
        this.nbreTotalTypeCustomer = this.listTypeCustomer.length;
      }

    }).catch((error)=>{

    });
  }
  toggleChoixSousMenu(str: string){
    this.choixSousMenu = str;
  }

  reloadDataCustomer(str: boolean){
    this._loadListTypeCustomer();
    console.log(str);

  }

  reloadDataWaste(str: boolean){
    this._loadListTypeWaste();
    console.log(str);
  }

}
