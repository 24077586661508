import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IResponse } from 'src/app/api/models/response.interface';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'cpropre-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isSignUp: boolean = false;
  loginError: any;
  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router:Router
    ) { }

  ngOnInit() {
    this.authService.logout()
  }

    /*
      change signIn template to signUp template and vice versa
    */
   public toggleAuthTempalte(toggleTempalte : boolean){
    this.isSignUp = toggleTempalte;
  }

  /*
    is run when de signIn submit is pressed
  */
  public userLogin(credentials: { email: string, password: string }) {
    this.authService.login(credentials).then((data) => {
      let response: IResponse = data;
      if(response.error)
        this.loginError = response.message;
      else{
        this.router.navigate(['dashboard']);
        //this.userService.loadCurrentUser();
      }
      // Login success
    })
    .catch((reason) => {
      this.loginError = "An error is detected, please retry again";
      console.log("Error reason",reason)
    })
  }

  getError(){
    return this.loginError;
  }

}
