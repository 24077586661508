import { Component, OnInit, Input } from '@angular/core';
import { Customer } from 'src/app/api/resources/customer_resource';

@Component({
  selector: 'cpropre-details-customer',
  templateUrl: './details-customer.component.html',
  styleUrls: ['./details-customer.component.scss']
})
export class DetailsCustomerComponent implements OnInit {

  @Input() customer:Customer;
  
  constructor() { }

  ngOnInit() {

  }

}
