export interface ICountry{
  name: string; 
  code: string; 
  dial_code: string;
  lang: string;
}

export var countries: ICountry[] = [
  {"name": "افغانستان", "code": "AF", "dial_code": "+93", "lang": "gk"},
  {"name": "Åland", "code": "AX", "dial_code": "+358", "lang": "gk"},
  {"name": "Shqipëria", "code": "AL", "dial_code": "+355", "lang": "gk"},
  {"name": "الجزائر", "code": "DZ", "dial_code": "+213", "lang": "gk"},
  {"name": "American Samoa", "code": "AS", "dial_code": "+1684", "lang": "gk"},
  {"name": "Andorra", "code": "AD", "dial_code": "+376", "lang": "gk"},
  {"name": "Angola", "code": "AO", "dial_code": "+244",lang: "gk"},
  {"name": "Anguilla", "code": "AI", "dial_code": "+1264", "lang": "gk"},
  {"name": "Antarctica", "code": "AQ", "dial_code": "+672", "lang": "gk"},
  {"name": "Antigua and Barbuda", "code": "AG", "dial_code": "+1268", "lang": "gk"},
  {"name": "Argentina", "code": "AR", "dial_code": "+54", "lang": "gk"},
  {"name": "Հայաստան", "code": "AM", "dial_code": "+374", "lang": "gk"},
  {"name": "Aruba", "code": "AW", "dial_code": "+297", "lang": "gk"},
  {"name": "Australia", "code": "AU", "dial_code": "+61", "lang": "gk"},
  {"name": "Österreich", "code": "AT", "dial_code": "+43", "lang": "gk"},
  {"name": "Azərbaycan", "code": "AZ", "dial_code": "+994", "lang": "gk"},
  {"name": "Bahamas", "code": "BS", "dial_code": "+1242", "lang": "gk"},
  {"name": "‏البحرين", "code": "BH", "dial_code": "+973", "lang": "gk"},
  {"name": "Bangladesh", "code": "BD", "dial_code": "+880", "lang": "gk"},
  {"name": "Barbados", "code": "BB", "dial_code": "+1246", "lang": "gk"},
  {"name": "Белару́сь", "code": "BY", "dial_code": "+375", "lang": "gk"},
  {"name": "België", "code": "BE", "dial_code": "+32", "lang": "gk"},
  {"name": "Belize", "code": "BZ", "dial_code": "+501", "lang": "gk"},
  {"name": "Bénin", "code": "BJ", "dial_code": "+229", "lang": "fr"},
  {"name": "Bermuda", "code": "BM", "dial_code": "+1441", "lang": "gk"},
  {"name": "ʼbrug-yul", "code": "BT", "dial_code": "+975", "lang": "gk"},
  {"name": "Bolivia", "code": "BO", "dial_code": "+591", "lang": "gk"},
  {"name": "Bosna i Hercegovina", "code": "BA", "dial_code": "+387", "lang": "gk"},
  {"name": "Botswana", "code": "BW", "dial_code": "+267", "lang": "gk"},
  {"name": "Bouvetøya", "code": "BV", "dial_code": "+47", "lang": "gk"},
  {"name": "Brasil", "code": "BR", "dial_code": "+55", "lang": "gk"},
  {"name": "British Indian Ocean Territory", "code": "IO", "dial_code": "+246", "lang": "gk"},
  {"name": "Negara Brunei Darussalam", "code": "BN", "dial_code": "+673", "lang": "gk"},
  {"name": "България", "code": "BG", "dial_code": "+359", "lang": "gk"},
  {"name": "Burkina Faso", "code": "BF", "dial_code": "+226", "lang": "gk"},
  {"name": "Burundi", "code": "BI", "dial_code": "+257", "lang": "gk"},
  {"name": "Kâmpŭchéa", "code": "KH", "dial_code": "+855", "lang": "gk"},
  {"name": "Cameroon", "code": "CM", "dial_code": "+237", "lang": "gk"},
  {"name": "Canada", "code": "CA", "dial_code": "+1", "lang": "gk"},
  {"name": "Cabo Verde", "code": "CV", "dial_code": "+238", "lang": "gk"},
  {"name": "Cayman Islands", "code": "KY", "dial_code": "+ 345", "lang": "gk"},
  {"name": "Ködörösêse tî Bêafrîka", "code": "CF", "dial_code": "+236", "lang": "gk"},
  {"name": "Tchad", "code": "TD", "dial_code": "+235", "lang": "gk"},
  {"name": "Chile", "code": "CL", "dial_code": "+56", "lang": "gk"},
  {"name": "中国", "code": "CN", "dial_code": "+86", "lang": "gk"},
  {"name": "Christmas Island", "code": "CX", "dial_code": "+61", "lang": "gk"},
  {"name": "Cocos (Keeling) Islands", "code": "CC", "dial_code": "+61", "lang": "gk"},
  {"name": "Colombia", "code": "CO", "dial_code": "+57", "lang": "gk"},
  {"name": "Komori", "code": "KM", "dial_code": "+269", "lang": "gk"},
  {"name": "République du Congo", "code": "CG", "dial_code": "+242", "lang": "gk"},
  {
    "name": "République démocratique du Congo",
    "code": "CD",
    "dial_code": "+243",
    "lang": "gk"
  },
  {"name": "Cook Islands", "code": "CK", "dial_code": "+682", "lang": "gk"},
  {"name": "Costa Rica", "code": "CR", "dial_code": "+506", "lang": "gk"},
  {"name": "Côte d'Ivoire", "code": "CI", "dial_code": "+225", "lang": "fr"},
  {"name": "Hrvatska", "code": "HR", "dial_code": "+385", "lang": "gk"},
  {"name": "Cuba", "code": "CU", "dial_code": "+53", "lang": "gk"},
  {"name": "Κύπρος", "code": "CY", "dial_code": "+357", "lang": "gk"},
  {"name": "Česká republika", "code": "CZ", "dial_code": "+420", "lang": "gk"},
  {"name": "Danmark", "code": "DK", "dial_code": "+45", "lang": "gk"},
  {"name": "Djibouti", "code": "DJ", "dial_code": "+253", "lang": "gk"},
  {"name": "Dominica", "code": "DM", "dial_code": "+1767", "lang": "gk"},
  {"name": "República Dominicana", "code": "DO", "dial_code": "+1849", "lang": "gk"},
  {"name": "Ecuador", "code": "EC", "dial_code": "+593", "lang": "gk"},
  {"name": "مصر‎", "code": "EG", "dial_code": "+20", "lang": "gk"},
  {"name": "El Salvador", "code": "SV", "dial_code": "+503", "lang": "gk"},
  {"name": "Guinea Ecuatorial", "code": "GQ", "dial_code": "+240", "lang": "gk"},
  {"name": "ኤርትራ", "code": "ER", "dial_code": "+291", "lang": "gk"},
  {"name": "Eesti", "code": "EE", "dial_code": "+372", "lang": "gk"},
  {"name": "ኢትዮጵያ", "code": "ET", "dial_code": "+251", "lang": "gk"},
  {"name": "Falkland Islands", "code": "FK", "dial_code": "+500", "lang": "gk"},
  {"name": "Føroyar", "code": "FO", "dial_code": "+298", "lang": "gk"},
  {"name": "Fiji", "code": "FJ", "dial_code": "+679", "lang": "gk"},
  {"name": "Suomi", "code": "FI", "dial_code": "+358", "lang": "gk"},
  {"name": "France", "code": "FR", "dial_code": "+33", "lang": "gk"},
  {"name": "Guyane française", "code": "GF", "dial_code": "+594", "lang": "gk"},
  {"name": "Polynésie française", "code": "PF", "dial_code": "+689", "lang": "gk"},
  {
    "name": "Territoire des Terres australes et antarctiques fr",
    "code": "TF",
    "dial_code": "+262",
    "lang": "gk"
  },
  {"name": "Gabon", "code": "GA", "dial_code": "+241", "lang": "gk"},
  {"name": "Gambia", "code": "GM", "dial_code": "+220", "lang": "gk"},
  {"name": "საქართველო", "code": "GE", "dial_code": "+995", "lang": "gk"},
  {"name": "Deutschland", "code": "DE", "dial_code": "+49", "lang": "gk"},
  {"name": "Ghana", "code": "GH", "dial_code": "+233", "lang": "en"},
  {"name": "Gibraltar", "code": "GI", "dial_code": "+350", "lang": "gk"},
  {"name": "Ελλάδα", "code": "GR", "dial_code": "+30", "lang": "gk"},
  {"name": "Kalaallit Nunaat", "code": "GL", "dial_code": "+299", "lang": "gk"},
  {"name": "Grenada", "code": "GD", "dial_code": "+1473", "lang": "gk"},
  {"name": "Guadeloupe", "code": "GP", "dial_code": "+590", "lang": "gk"},
  {"name": "Guam", "code": "GU", "dial_code": "+1671", "lang": "gk"},
  {"name": "Guatemala", "code": "GT", "dial_code": "+502", "lang": "gk"},
  {"name": "Guernsey", "code": "GG", "dial_code": "+44", "lang": "gk"},
  {"name": "Guinée", "code": "GN", "dial_code": "+224", "lang": "gk"},
  {"name": "Guiné-Bissau", "code": "GW", "dial_code": "+245", "lang": "gk"},
  {"name": "Guyana", "code": "GY", "dial_code": "+592", "lang": "gk"},
  {"name": "Haïti", "code": "HT", "dial_code": "+509", "lang": "gk"},
  {
    "name": "Heard Island and McDonald Islands",
    "code": "HM",
    "dial_code": "+0",
    "lang": "gk"
  },
  {"name": "Vaticano", "code": "VA", "dial_code": "+379", "lang": "gk"},
  {"name": "Honduras", "code": "HN", "dial_code": "+504", "lang": "gk"},
  {"name": "香港", "code": "HK", "dial_code": "+852", "lang": "gk"},
  {"name": "Magyarország", "code": "HU", "dial_code": "+36", "lang": "gk"},
  {"name": "Ísland", "code": "IS", "dial_code": "+354", "lang": "gk"},
  {"name": "भारत", "code": "IN", "dial_code": "+91", "lang": "gk"},
  {"name": "Indonesia", "code": "ID", "dial_code": "+62", "lang": "gk"},
  {"name": "ایران", "code": "IR", "dial_code": "+98", "lang": "gk"},
  {"name": "العراق", "code": "IQ", "dial_code": "+964", "lang": "gk"},
  {"name": "Éire", "code": "IE", "dial_code": "+353", "lang": "gk"},
  {"name": "Isle of Man", "code": "IM", "dial_code": "+44", "lang": "gk"},
  {"name": "יִשְׂרָאֵל", "code": "IL", "dial_code": "+972", "lang": "gk"},
  {"name": "Italia", "code": "IT", "dial_code": "+39", "lang": "gk"},
  {"name": "Jamaica", "code": "JM", "dial_code": "+1876", "lang": "gk"},
  {"name": "日本", "code": "JP", "dial_code": "+81", "lang": "gk"},
  {"name": "Jersey", "code": "JE", "dial_code": "+44", "lang": "gk"},
  {"name": "الأردن", "code": "JO", "dial_code": "+962", "lang": "gk"},
  {"name": "Қазақстан", "code": "KZ", "dial_code": "+7", "lang": "gk"},
  {"name": "Kenya", "code": "KE", "dial_code": "+254", "lang": "gk"},
  {"name": "Kiribati", "code": "KI", "dial_code": "+686", "lang": "gk"},
  {"name": "북한", "code": "KP", "dial_code": "+850", "lang": "gk"},
  {"name": "대한민국", "code": "KR", "dial_code": "+82", "lang": "gk"},
  {"name": "Republika e Kosovës", "code": "XK", "dial_code": "+383", "lang": "gk"},
  {"name": "الكويت", "code": "KW", "dial_code": "+965", "lang": "gk"},
  {"name": "Кыргызстан", "code": "KG", "dial_code": "+996", "lang": "gk"},
  {"name": "ສປປລາວ", "code": "LA", "dial_code": "+856", "lang": "gk"},
  {"name": "Latvija", "code": "LV", "dial_code": "+371", "lang": "gk"},
  {"name": "لبنان", "code": "LB", "dial_code": "+961", "lang": "gk"},
  {"name": "Lesotho", "code": "LS", "dial_code": "+266", "lang": "gk"},
  {"name": "Liberia", "code": "LR", "dial_code": "+231", "lang": "gk"},
  {"name": "‏ليبيا", "code": "LY", "dial_code": "+218", "lang": "gk"},
  {"name": "Liechtenstein", "code": "LI", "dial_code": "+423", "lang": "gk"},
  {"name": "Lietuva", "code": "LT", "dial_code": "+370", "lang": "gk"},
  {"name": "Luxembourg", "code": "LU", "dial_code": "+352", "lang": "gk"},
  {"name": "澳門", "code": "MO", "dial_code": "+853", "lang": "gk"},
  {"name": "Македонија", "code": "MK", "dial_code": "+389", "lang": "gk"},
  {"name": "Madagasikara", "code": "MG", "dial_code": "+261", "lang": "gk"},
  {"name": "Malawi", "code": "MW", "dial_code": "+265", "lang": "gk"},
  {"name": "Malaysia", "code": "MY", "dial_code": "+60", "lang": "gk"},
  {"name": "Maldives", "code": "MV", "dial_code": "+960", "lang": "gk"},
  {"name": "Mali", "code": "ML", "dial_code": "+223", "lang": "gk"},
  {"name": "Malta", "code": "MT", "dial_code": "+356", "lang": "gk"},
  {"name": "M̧ajeļ", "code": "MH", "dial_code": "+692", "lang": "gk"},
  {"name": "Martinique", "code": "MQ", "dial_code": "+596", "lang": "gk"},
  {"name": "موريتانيا", "code": "MR", "dial_code": "+222", "lang": "gk"},
  {"name": "Maurice", "code": "MU", "dial_code": "+230", "lang": "gk"},
  {"name": "Mayotte", "code": "YT", "dial_code": "+262", "lang": "gk"},
  {"name": "México", "code": "MX", "dial_code": "+52", "lang": "gk"},
  {"name": "Micronesia", "code": "FM", "dial_code": "+691", "lang": "gk"},
  {"name": "Moldova", "code": "MD", "dial_code": "+373", "lang": "gk"},
  {"name": "Monaco", "code": "MC", "dial_code": "+377", "lang": "gk"},
  {"name": "Монгол улс", "code": "MN", "dial_code": "+976", "lang": "gk"},
  {"name": "Црна Гора", "code": "ME", "dial_code": "+382", "lang": "gk"},
  {"name": "Montserrat", "code": "MS", "dial_code": "+1664", "lang": "gk"},
  {"name": "المغرب", "code": "MA", "dial_code": "+212", "lang": "gk"},
  {"name": "Moçambique", "code": "MZ", "dial_code": "+258", "lang": "gk"},
  {"name": "Myanma", "code": "MM", "dial_code": "+95", "lang": "gk"},
  {"name": "Namibia", "code": "NA", "dial_code": "+264", "lang": "gk"},
  {"name": "Nauru", "code": "NR", "dial_code": "+674", "lang": "gk"},
  {"name": "नपल", "code": "NP", "dial_code": "+977", "lang": "gk"},
  {"name": "Nederland", "code": "NL", "dial_code": "+31", "lang": "gk"},
  {"name": "Netherlands Antilles", "code": "AN", "dial_code": "+599", "lang": "gk"},
  {"name": "Nouvelle-Calédonie", "code": "NC", "dial_code": "+687", "lang": "gk"},
  {"name": "New Zealand", "code": "NZ", "dial_code": "+64", "lang": "gk"},
  {"name": "Nicaragua", "code": "NI", "dial_code": "+505", "lang": "gk"},
  {"name": "Niger", "code": "NE", "dial_code": "+227", "lang": "gk"},
  {"name": "Nigeria", "code": "NG", "dial_code": "+234", "lang": "gk"},
  {"name": "Niuē", "code": "NU", "dial_code": "+683", "lang": "gk"},
  {"name": "Norfolk Island", "code": "NF", "dial_code": "+672", "lang": "gk"},
  {"name": "Northern Mariana Islands", "code": "MP", "dial_code": "+1670", "lang": "gk"},
  {"name": "Norge", "code": "NO", "dial_code": "+47", "lang": "gk"},
  {"name": "عمان", "code": "OM", "dial_code": "+968", "lang": "gk"},
  {"name": "Pakistan", "code": "PK", "dial_code": "+92", "lang": "gk"},
  {"name": "Palau", "code": "PW", "dial_code": "+680", "lang": "gk"},
  {"name": "فلسطين", "code": "PS", "dial_code": "+970", "lang": "gk"},
  {"name": "Panamá", "code": "PA", "dial_code": "+507", "lang": "gk"},
  {"name": "Papua Niugini", "code": "PG", "dial_code": "+675", "lang": "gk"},
  {"name": "Paraguay", "code": "PY", "dial_code": "+595", "lang": "gk"},
  {"name": "Perú", "code": "PE", "dial_code": "+51", "lang": "gk"},
  {"name": "Pilipinas", "code": "PH", "dial_code": "+63", "lang": "gk"},
  {"name": "Pitcairn Islands", "code": "PN", "dial_code": "+64", "lang": "gk"},
  {"name": "Polska", "code": "PL", "dial_code": "+48", "lang": "gk"},
  {"name": "Portugal", "code": "PT", "dial_code": "+351", "lang": "gk"},
  {"name": "Puerto Rico", "code": "PR", "dial_code": "+1939", "lang": "gk"},
  {"name": "قطر", "code": "QA", "dial_code": "+974", "lang": "gk"},
  {"name": "România", "code": "RO", "dial_code": "+40", "lang": "gk"},
  {"name": "Россия", "code": "RU", "dial_code": "+7", "lang": "gk"},
  {"name": "Rwanda", "code": "RW", "dial_code": "+250", "lang": "gk"},
  {"name": "La Réunion", "code": "RE", "dial_code": "+262", "lang": "gk"},
  {"name": "Saint-Barthélemy", "code": "BL", "dial_code": "+590", "lang": "gk"},
  {"name": "Saint Helena", "code": "SH", "dial_code": "+290", "lang": "gk"},
  {"name": "Saint Kitts and Nevis", "code": "KN", "dial_code": "+1869", "lang": "gk"},
  {"name": "Saint Lucia", "code": "LC", "dial_code": "+1758", "lang": "gk"},
  {"name": "Saint-Martin", "code": "MF", "dial_code": "+590", "lang": "gk"},
  {"name": "Saint-Pierre-et-Miquelon", "code": "PM", "dial_code": "+508", "lang": "gk"},
  {
    "name": "Saint Vincent and the Grenadines",
    "code": "VC",
    "dial_code": "+1784",
    "lang": "gk"
  },
  {"name": "Samoa", "code": "WS", "dial_code": "+685", "lang": "gk"},
  {"name": "San Marino", "code": "SM", "dial_code": "+378", "lang": "gk"},
  {"name": "São Tomé e Príncipe", "code": "ST", "dial_code": "+239", "lang": "gk"},
  {"name": "العربية السعودية", "code": "SA", "dial_code": "+966", "lang": "gk"},
  {"name": "Sénégal", "code": "SN", "dial_code": "+221", "lang": "gk"},
  {"name": "Србија", "code": "RS", "dial_code": "+381", "lang": "gk"},
  {"name": "Seychelles", "code": "SC", "dial_code": "+248", "lang": "gk"},
  {"name": "Sierra Leone", "code": "SL", "dial_code": "+232", "lang": "gk"},
  {"name": "Singapore", "code": "SG", "dial_code": "+65", "lang": "gk"},
  {"name": "Slovensko", "code": "SK", "dial_code": "+421", "lang": "gk"},
  {"name": "Slovenija", "code": "SI", "dial_code": "+386", "lang": "gk"},
  {"name": "Solomon Islands", "code": "SB", "dial_code": "+677", "lang": "gk"},
  {"name": "Soomaaliya", "code": "SO", "dial_code": "+252", "lang": "gk"},
  {"name": "South Africa", "code": "ZA", "dial_code": "+27", "lang": "gk"},
  {"name": "South Sudan", "code": "SS", "dial_code": "+211", "lang": "gk"},
  {"name": "South Georgia", "code": "GS", "dial_code": "+500", "lang": "gk"},
  {"name": "España", "code": "ES", "dial_code": "+34", "lang": "gk"},
  {"name": "śrī laṃkāva", "code": "LK", "dial_code": "+94", "lang": "gk"},
  {"name": "السودان", "code": "SD", "dial_code": "+249", "lang": "gk"},
  {"name": "Suriname", "code": "SR", "dial_code": "+597", "lang": "gk"},
  {"name": "Svalbard og Jan Mayen", "code": "SJ", "dial_code": "+47", "lang": "gk"},
  {"name": "Swaziland", "code": "SZ", "dial_code": "+268", "lang": "gk"},
  {"name": "Sverige", "code": "SE", "dial_code": "+46", "lang": "gk"},
  {"name": "Schweiz", "code": "CH", "dial_code": "+41", "lang": "gk"},
  {"name": "سوريا", "code": "SY", "dial_code": "+963", "lang": "gk"},
  {"name": "臺灣", "code": "TW", "dial_code": "+886", "lang": "gk"},
  {"name": "Тоҷикистон", "code": "TJ", "dial_code": "+992", "lang": "gk"},
  {"name": "Tanzania", "code": "TZ", "dial_code": "+255", "lang": "gk"},
  {"name": "ประเทศไทย", "code": "TH", "dial_code": "+66", "lang": "gk"},
  {"name": "Timor-Leste", "code": "TL", "dial_code": "+670", "lang": "gk"},
  {"name": "Togo", "code": "TG", "dial_code": "+228", "lang": "en"},
  {"name": "Tokelau", "code": "TK", "dial_code": "+690", "lang": "gk"},
  {"name": "Tonga", "code": "TO", "dial_code": "+676", "lang": "gk"},
  {"name": "Trinidad and Tobago", "code": "TT", "dial_code": "+1868", "lang": "gk"},
  {"name": "تونس", "code": "TN", "dial_code": "+216", "lang": "gk"},
  {"name": "Türkiye", "code": "TR", "dial_code": "+90", "lang": "gk"},
  {"name": "Türkmenistan", "code": "TM", "dial_code": "+993", "lang": "gk"},
  {"name": "Turks and Caicos Islands", "code": "TC", "dial_code": "+1649", "lang": "gk"},
  {"name": "Tuvalu", "code": "TV", "dial_code": "+688", "lang": "gk"},
  {"name": "Uganda", "code": "UG", "dial_code": "+256", "lang": "gk"},
  {"name": "Україна", "code": "UA", "dial_code": "+380", "lang": "gk"},
  {"name": "دولة الإمارات العربية المتحدة", "code": "AE", "dial_code": "+971", "lang": "gk"},
  {"name": "United Kingdom", "code": "GB", "dial_code": "+44", "lang": "gk"},
  {"name": "United States", "code": "US", "dial_code": "+1", "lang": "gk"},
  {"name": "Uruguay", "code": "UY", "dial_code": "+598", "lang": "gk"},
  {"name": "O‘zbekiston", "code": "UZ", "dial_code": "+998", "lang": "gk"},
  {"name": "Vanuatu", "code": "VU", "dial_code": "+678", "lang": "gk"},
  {"name": "Venezuela", "code": "VE", "dial_code": "+58", "lang": "gk"},
  {"name": "Việt Nam", "code": "VN", "dial_code": "+84", "lang": "gk"},
  {"name": "British Virgin Islands", "code": "VG", "dial_code": "+1284", "lang": "gk"},
  {"name": "United States Virgin Islands", "code": "VI", "dial_code": "+1340", "lang": "gk"},
  {"name": "Wallis et Futuna", "code": "WF", "dial_code": "+681", "lang": "gk"},
  {"name": "اليَمَن", "code": "YE", "dial_code": "+967", "lang": "gk"},
  {"name": "Zambia", "code": "ZM", "dial_code": "+260", "lang": "gk"},
  {"name": "Zimbabwe", "code": "ZW", "dial_code": "+263", "lang": "gk"}
];
