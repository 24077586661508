import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';
import { ITypeCustomerPayload } from 'src/app/services/payload/type-customer.payload';

@Component({
  selector: 'sesa-add-type-customer',
  templateUrl: './add-type-customer.component.html',
  styleUrls: ['./add-type-customer.component.scss']
})
export class AddTypeCustomerComponent implements OnInit, OnChanges {

  formGrp: FormGroup;

  @Input() typeCustomer: TypeCustomer;
  
  @Input() errorMessage: string;
  @Output() saveTypeCustomerEvent = new EventEmitter<ITypeCustomerPayload>();
  mailPattern: "/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i";
  
  @Input()  isRegisterSuccess: boolean = false;

  constructor() { }

  ngOnInit() {
    this._iniForm();
  }

  ngOnChanges(changes) {
    if(this.isRegisterSuccess){
      console.log("isRegisterSuccess",this.isRegisterSuccess)
      this.formGrp.reset();
    }

    if(this.typeCustomer && !this.isRegisterSuccess) {
      this.formGrp.patchValue({
        "lib": this.typeCustomer.lib
      })
    }
  }

  _iniForm(){
    this.formGrp = new FormGroup({
      lib: new FormControl(this.typeCustomer != null ? this.typeCustomer.lib : null, [Validators.required, Validators.pattern(/[0-9a-zA-Z]/)], null),
      
    });
  }

  onSubmit(){
    
    if(this.formGrp.invalid) {
      console.log("Error adding type customer")
    }
    else {
      this.saveTypeCustomerEvent.emit(this.formGrp.value);
    }
  }

  onReset() {
    this.formGrp.reset()
  }

}
