import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IResources } from '../api/resources/api-resources';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { IResponse } from '../api/models/response.interface';
import { Stats } from '../api/resources/stats_resource';
import { TypeCustomer } from '../api/resources/type_customer_resource';
import { Customer } from '../api/resources/customer_resource';

@Injectable()
export class StatistiqueService{
    loadingStat: Subject<boolean> = new Subject<boolean>();
    _loadStats : ResourceModel<Stats>
    _loadTypeWasteStats: any;
    loadingTypeWaste: Subject<boolean> = new Subject<boolean>();
    _listTypeWaste: any = [];
    loadingReward: Subject<boolean> = new Subject<boolean>();
    _listReward: any = [];
    listTypeCustomer: Subject<ResourceModel<TypeCustomer[]>> = new Subject<ResourceModel<TypeCustomer[]>>();
    listCustomer : Subject<ResourceModel<Customer[]>> = new Subject<ResourceModel<Customer[]>>();

    private ngxApiResources: IResources ;

    constructor(private _ngxApiResources: NgxApiResources) {
      this.ngxApiResources = this._ngxApiResources.loadResources;
    }


  getTopStat() {
    let promise = this.ngxApiResources.statsResource._loadTopStat().$promise;
    return promise;
  }

  getPool(dateRange: Object ){
    let promise = this.ngxApiResources.statsResource._loadPool(dateRange).$promise;
    return promise;
  }

  public getTypeWasteStat(dateRange: Object) {
    let promise = this.ngxApiResources.statsResource._loadStatsTypeWaste(dateRange).$promise;
    return promise;
  }

  public getRewardStat(dateRange: Object) {
    let promise = this.ngxApiResources.statsResource._loadStatsReward(dateRange).$promise;
    return promise;
  }

  //Top10TranDetailOntimeUrl
  public getTop10TranDetailOntime(dateRange: Object) {
    let promise = this.ngxApiResources.statsResource._loadStatsTop10TranDetailOntimeUrl(dateRange).$promise;
    return promise;
  }

  // getStatsOnPoints per month
  public getStatsOnPoints() {
    let promise = this.ngxApiResources.statsResource._loadStatsOnPoints().$promise;
    return promise;
  }
}
