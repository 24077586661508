import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { DateRangePickerComponent, IDateRange, IDateRangePickerOptions } from 'ngx-daterange';
import * as moment from 'moment';

@Component({
  selector: 'sesa-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss']
})
export class DatepickerComponent implements OnInit {
  //@ViewChild('dateRangePicker', { static: true })
  //dateRangePicker: DateRangePickerComponent;

  //firstFieldEmittedValue: IDateRange;
  // firstFieldOptions: IDateRangePickerOptions = {
  //   autoApply: false,
  //   format: 'MM/DD/YYYY',
  //   icons: 'material',
  //   minDate: moment().subtract(10, 'years'),
  //   maxDate: moment().add(3, 'years'),
  //   preDefinedRanges: [
  //     {
  //       name: 'Last Week',
  //       value: {
  //         start: moment().subtract(1, 'week').startOf('week'),
  //         end: moment().subtract(1, 'week').endOf('week')
  //       }
  //     },
  //     {
  //       name: 'Two Weeks Ago',
  //       value: {
  //         start: moment().subtract(2, 'week').startOf('week'),
  //         end: moment().subtract(2, 'week').endOf('week')
  //       }
  //     }
  //   ],
  //   validators: Validators.required,
  // }

  // secondFieldOptions: IDateRangePickerOptions = {
  //   autoApply: false,
  //   clickOutsideAllowed: false,
  //   format: 'MM/DD/YYYY',
  //   icons: 'font-awesome',
  //   minDate: moment().subtract(10, 'years'),
  //   maxDate: moment().add(1, 'year'),
  // }

  // rightFieldOptions: IDateRangePickerOptions = {
  //   format: 'MM/DD/YYYY',
  //   icons: 'material',
  //   minDate: moment().subtract(2, 'years'),
  //   maxDate: moment().add(1, 'year'),
  //   position: 'right',
  // }

  // singleFieldOptions: IDateRangePickerOptions = {
  //   autoApply: true,
  //   clickOutsideAllowed: false,
  //   format: 'MM/DD/YYYY',
  //   icons: 'material',
  //   labelText: 'Single Picker',
  //   minDate: moment().subtract(2, 'years'),
  //   maxDate: moment().add(1, 'year'),
  //   singleCalendar: true,
  // }

  form: FormGroup = null;

  constructor(
    private formBuilder: FormBuilder
  ) { 
    
  }

  ngOnInit() {
    this.form = this.formBuilder.group({});
  }

  // onRangeSelected(value: IDateRange): void {
  //   this.firstFieldEmittedValue = value;
  // }

  // onReset(event: Event): void {
  //   this.dateRangePicker.reset(event);
  // }

}
