import { Component, OnInit,Input ,Output,EventEmitter} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { INumberToGeneratePayload } from 'src/app/services/payload/qrcode-logger.payload';

@Component({
  selector: 'cpropre-add-logger',
  templateUrl: './add-logger.component.html',
  styleUrls: ['./add-logger.component.scss']
})
export class AddLoggerComponent implements OnInit {

  addLoggerForm: FormGroup;
  
  @Input() errorMessage: string;
  //@Output() changeTemplate = new EventEmitter<boolean>()
  @Output() saveLoggerEvent = new EventEmitter<INumberToGeneratePayload>();
  constructor() { }

  ngOnInit() {
    this._iniForm()
  }

  _iniForm(){
    this.addLoggerForm = new FormGroup({
      nomberlogger: new FormControl(null, [Validators.required, Validators.pattern(  /[0-9]/)], null),
      
    });
  }

  onSubmit(){
    console.log(this.addLoggerForm.value);
    this.saveLoggerEvent.emit(this.addLoggerForm.value);
  }

}
