import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';
import { Account } from './account_resource';
import { TypeCustomer } from './type_customer_resource';

/**
  * Class CustomerResource
  * @author TINA K. Arouna <inbox@xcodevs.com>
  * @package
  * Route("")
*/

export class Customer extends ResourceInstance {
    pk: number;
    ref: string;
    typecustomer: TypeCustomer;
    name: string;
    hbd: string;
    gender: string;
    gsm: string;
    email: string;
    address: string;
    area: string;
    city: string;
    country: string;
    account: Account;
    createdat: string;
    updatedat: string;
    from: string;
  }
  
  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'CustomerResource',
      url: environment.apiUrl, //+ 'crm/:pk/',//user/
      pkAttr: 'pk',
      instanceClass: Customer,
      stripTrailingSlashes: true,
  })
  export class CustomerResource extends Resource<Customer> {
  
    constructor(
      registry: ResourceRegistry,
      http: HttpClient) {
      super(registry, http);
    }
  
    @ResourceAction(Apiroutes.listCustomerUrl)
    _listCustomer: ResourceActionMethod<any, any, Customer>;
  }