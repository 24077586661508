import { Component, OnInit,AfterViewInit, ViewChild } from '@angular/core';
import { customeTheme } from './@custome-theme';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { ColumnMode, DatatableComponent, SelectionType,SortType } from '@swimlane/ngx-datatable';
import { CustomerService } from 'src/app/services/customer.service';
import { IResponse } from 'src/app/api/models/response.interface';
import { Customer } from 'src/app/api/resources/customer_resource';
import { iCheckInit } from 'src/app/@themes/icheck-active';
import { dataTableInit } from 'src/app/@themes/data-table-act';
declare const $: any;

@Component({
  selector: 'cpropre-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  
})
export class CustomerComponent implements OnInit,AfterViewInit {

  customer :any;

  customers = [];
  temp = [];
  selected = [];
  sortType = SortType;
  columnMode = ColumnMode;
  selectionType = SelectionType;
  displayTableOption: boolean = false;
  @ViewChild(DatatableComponent) table: DatatableComponent;

  constructor(private customerService: CustomerService) { }

  ngOnInit() {
    this.getCustomers();
  }

  ngAfterViewInit(){
    //iCheckInit($)
    //dataTableInit($)
  }

  public getCustomers() {
    this.customerService.listCustomer().then((data)=> {
      let response: IResponse = data;
      let listCustomers : ResourceModel<Customer[]>;
      if(!response.error) {
        listCustomers = response.content.sort((a,b) => 0 - (a > b ? -1 : 1));
        this.customers = listCustomers
        this.temp = listCustomers //Cache
        console.log("Customers" )
        console.log(response.content)
      }
    }).catch((reason)=> {

    })
  }

  onSelect({ selected }){
    console.log('Select Event', this.selected);

    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
    //this.totalToProductSelected = this.selected.length;
  }

  onActivate(event) {
    console.log('Activate Event', event);
    if(event.type == 'click'){
      this.customer = event.row;
      this.displayTableOption = !this.displayTableOption
      console.log(this.customer);
    }

    switch (event.type) {
      case 'click':
        this.customer = event.row;
        console.log(this.selectionType);
        break;
    
      default:
        break;
    }
  }

  displayCheck(row) {
    console.log("displayTableOption : " + this.displayTableOption)
    this.displayTableOption = !this.displayTableOption
    return row;
  }

  //Sort
  updateFilter(event) {
    const val = event.target.value.toLowerCase();
    
    // filter our data
    const temp = this.temp.filter(function (d) {
      console.log(d)
      return d.name.toLowerCase().indexOf(val) !== -1 || 
      d.typecustomer.lib.toLowerCase().indexOf(val) !== -1 ||
      d.gender.toLowerCase().indexOf(val) !== -1 ||
      d.country.toLowerCase().indexOf(val) !== -1 ||
      d.city.toLowerCase().indexOf(val) !== -1 || 
      d.gsm.toLowerCase().indexOf(val) !== -1 ||
      d.address.toLowerCase().indexOf(val) !== -1 ||
      d.createdat.toLowerCase().indexOf(val) !== -1 ||
      d.from.toLowerCase().indexOf(val) !== -1 ||
      !val;
    });

    // update the rows
    this.customers = temp;
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  toggleExpandRow(row) {
    console.log('Toggled Expand Row!', row);
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
    console.log('Detail Toggled', event);
  }
}
