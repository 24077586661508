import { Injectable } from '@angular/core';
import { ResourceModel } from "ngx-resource-factory/resource/resource-model";
import { Subject } from "rxjs"; ///Subject
import { User } from 'src/app/api/resources/user_resource';
import { Product } from '../../api/entities/product.entity';


export interface Scope {
  area: 'user' | 'products' |'global' | null;
  value: ResourceModel<User | Product[]>;
}

@Injectable()
export class ActiveScopeService {

  private activeScope: Scope;

  scopeUpdated = new Subject<Scope>();

  constructor() {
  }

  getScope() {
    return this.activeScope;
  }

  updateScope(scope: Scope) {
    console.log("Scope updated");
    console.log(scope);

    this.activeScope = scope;
    this.scopeUpdated.next(scope);
  }

  refreshScope() {
    this.scopeUpdated.next(this.activeScope);
  }
}
