import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { IMenu } from 'src/app/utils/utils';

@Component({
  selector: 'sesa-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit,AfterViewInit {

  @Input() menus: IMenu[] = []
  
  constructor(
    private authService: AuthService
    ) { }

  ngOnInit() {
    
  }

  ngAfterViewInit(){
    //this.menus = this.menuService.getMenus()
    //console.log("Menus----", this.menus)
  }

  logOut(){
    this.authService.logout();
  }

  getRouterLink(menuLink:string, submenuLink: string): string[] {
    return [menuLink,submenuLink]
  }

}
