import { Injectable } from '@angular/core';

import { Resource } from 'ngx-resource-factory/resource/resource';
import { ResourceConfiguration } from 'ngx-resource-factory/resource/resource-configuration';
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceRegistry } from 'ngx-resource-factory/resource/resource-registry';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ResourceInstance } from 'ngx-resource-factory/resource/resource-instance';
import { Apiroutes } from '../routes/api_routes';


/**
  * Class RewardsResource
  * @package
  * Route("")
*/
export class Reward extends ResourceInstance {
    ref: string;
    lib: string;
    uvp: number;
    uvm: number;
    imageurl: string;
    createdat: string;
    updatedat: string;
    enabled: boolean;
    ord: number;
  }

  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'RewardsResource',
      url: environment.apiUrl,
      instanceClass: Reward,
      stripTrailingSlashes: true,
  })
  export class RewardsResource extends Resource<Reward> {

    constructor(
      registry: ResourceRegistry,
      http: HttpClient) {
      super(registry, http);
    }

    @ResourceAction(Apiroutes.listRewardUrl)
    _loadRewards: ResourceActionMethod<any, any, Reward>;

    @ResourceAction(Apiroutes.addRewardUrl)
    _addReward: ResourceActionMethod<any, any, Reward>;

    @ResourceAction(Apiroutes.deleteRewardUrl)
    delete: ResourceActionMethod<any, any, Reward>;

    @ResourceAction(Apiroutes.updateRewardUrl)
    _update: ResourceActionMethod<any, any, Reward>;

    @ResourceAction(Apiroutes.toggleEnableRewardUrl)
    _toggleEnable: ResourceActionMethod<any, any, Reward>;

    @ResourceAction(Apiroutes.updateOrderRewardUrl)
    _updateOrder: ResourceActionMethod<any, any, Reward>;
  }
