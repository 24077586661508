import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { IResources } from '../api/resources/api-resources';
import { NgxApiResources } from '../api/api_services/ngx-api-resources';
import { IRewardPayload } from './payload/reward.payload';

@Injectable()
export class  RewardService {

  private ngxApiResources: IResources;
  addingReward: Subject<boolean> = new Subject<boolean>();
  constructor(private _ngxApiResources: NgxApiResources) {
    this.ngxApiResources = this._ngxApiResources.loadResources;
  }

  // ADD REWARD
  addReward(rewardPayload: IRewardPayload) {
    let promise = this.ngxApiResources.rewardsResource._addReward({}, rewardPayload).$promise;
    return promise;
  }

  // LOAD REWARD
  loadRewards(){
    let promise = this.ngxApiResources.rewardsResource._loadRewards().$promise;

    return promise;
  }

  // REMOVE
  public remove(ref: string) {
    let promise = this.ngxApiResources.rewardsResource.delete({ref}).$promise;

    return promise;
  }

  // Enable

  public toggleEnable(ref: string) {
    let promise = this.ngxApiResources.rewardsResource._toggleEnable({ref}).$promise;

    return promise;
  }

  public update(ref: string, rewardPayload: IRewardPayload) {
    let promise = this.ngxApiResources.rewardsResource._update({ref}, rewardPayload).$promise;
    return promise;
  }

  public updateOrder(order: Object) {
    let promise = this.ngxApiResources.rewardsResource._updateOrder({},order).$promise;

    return promise;
  }
}
