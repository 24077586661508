import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/api/resources/user_resource';
import { UserService } from 'src/app/services/user.service';
import { getRoleTitle } from 'src/app/utils/utils';

@Component({
  selector: 'sesa-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  user: User;
  constructor(
    private userService: UserService
  ) { }

  ngOnInit() {
    this.user = this.userService.getCurrentUser()
  }

  public getRoleTitle(userRoles: string[]) {
    return getRoleTitle(userRoles);
  }

}
