import { Component, OnInit } from '@angular/core';
import { ResourceModel } from 'ngx-resource-factory/resource/resource-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { IResponse } from 'src/app/api/models/response.interface';
import { TypeCustomer } from 'src/app/api/resources/type_customer_resource';
import { TypeCustomerService } from 'src/app/services/typecustomer.service';
import { Alert, IUserAction, map2Obj, SubmitAction } from 'src/app/utils/utils';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { ITypeCustomerPayload } from 'src/app/services/payload/type-customer.payload';
import { UserService } from 'src/app/services/user.service';
declare const swal: any;

@Component({
  selector: 'sesa-type-customer',
  templateUrl: './type-customer.component.html',
  styleUrls: ['./type-customer.component.scss']
})
export class TypeCustomerComponent implements OnInit {
  userActions: IUserAction;
  typeCustomers: ResourceModel<TypeCustomer[]>;
  flashBag: Alert;
  typeCustomerSelected: TypeCustomer;
  errorAddTypeCustomer: string;
  submitAction: SubmitAction = SubmitAction.ADDING;

  isUpdateOrderOn=false;
  isRegisterSuccess: boolean = false;

  constructor(
    private typeCustomerService: TypeCustomerService,
    private spinner: NgxSpinnerService,
    private userService: UserService) { 
      this.userActions = this.userService.getCurrentUserActions()
    }

  ngOnInit() {
    this.flashBag = {visibility: false,message: "",backgroudColor: ""}
    this.loadTypeCustomers()
  }

  //Load Type customer
  public loadTypeCustomers(){
    this.typeCustomerService.listTypeCustomer().then((data)=>{
      let response: IResponse = data
      if(!response.error){
        this.typeCustomers = response.content;
      }

    }).catch((error)=>{

    });
  }

  // LIST DRAG AND DROP
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.typeCustomers, event.previousIndex, event.currentIndex);
    this.isUpdateOrderOn=true;
  }

  public updateOrder() {
    if(this.isUpdateOrderOn) {
      let params: Map<number, string> = new Map();
      this.typeCustomers.forEach((typseCustomer,i) =>{
        params.set(i,typseCustomer.ref);
      })
      console.log(params)
      this.spinner.show("sp-order");
      this.typeCustomerService.updateOrder(map2Obj(params)).then((data)=> {
        let response: IResponse = data;
        if(!response.error){
          console.log(response.message)
        }
        else {
          console.log(response.message)
        }
        this.spinner.hide("sp-order");
        this.isUpdateOrderOn = false;
      }).catch((reason)=> {
        this.isUpdateOrderOn = false;
        console.log(reason)
        this.spinner.show("sp-order");
      })
    } 
  }

  onToggleEnabled(typeCustomerSelected: TypeCustomer) {
    this.typeCustomerService.toggleEnable(typeCustomerSelected.ref).then((data)=> {
      let response: IResponse = data;

      if(!response.error) {
        this.loadTypeCustomers()
      }
    }).catch((reason)=> {
      console.log(reason)
    })
  }
  onTypeCustomerSelected(t) {
    console.log(t)
    this.typeCustomerSelected = t;
    this.submitAction = SubmitAction.EDITING;
  }

  // ADD TYPE CUSTOMER
  onAddTypeCustomer(typeCustomerPayload: ITypeCustomerPayload){
    this.spinner.show()
    let promise;
    if(this.submitAction == SubmitAction.ADDING) {
      console.log('Adding')
      promise = this.typeCustomerService.addTypeWaste(typeCustomerPayload);
    }
    else if(this.submitAction == SubmitAction.EDITING) {
      console.log('Editing')
      promise = this.typeCustomerService.updateTypeCustomer(this.typeCustomerSelected.ref,typeCustomerPayload);
    }

    promise.then((data) => {
      let response: IResponse = data;
      console.log(response)
      this.spinner.hide()

      if(!response.error) {
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-success alert-dismissible"
        }
        this.loadTypeCustomers();
        swal("Success!", "Waste successfuly has been added", "success")
        this.isRegisterSuccess = true
        
      }
      else {
        this.errorAddTypeCustomer = response.message
        this.flashBag =  {
          visibility: true,
          message: response.message,
          backgroudColor: "alert alert-danger alert-dismissible"
        }

        swal({
          icon: 'error',   
          title: "Ooops !",   
          text: this.flashBag.message,   
          type: "warning",   
          showCancelButton: false,   
          confirmButtonText: "Ok",
        })
      }


    })
    .catch((reason) => {
      this.spinner.hide()

      swal({
        icon: 'error',   
        title: "Ooops !",   
        text: "An error has been detected, please contact the support at support@sesa.tech",   
        type: "warning",   
        showCancelButton: false,   
        confirmButtonText: "Ok",
      })
    });
  }
}
