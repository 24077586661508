import { ModuleWithProviders, NgModule } from '@angular/core';
import { ActiveScopeService } from './navigation/activeScope.service';
import { AuthService } from './auth.service';
import { CustomerService } from './customer.service';
import { StatistiqueService } from './statistique.service';
import { TypeCustomerService } from './typecustomer.service';
import { TypeWasteService } from './typewaste.service';
import { UserService } from './user.service';
import { RewardService } from './reward.service';
import { CityService } from './city.service';
import { AuthGuardService } from './AuthGuardService';
import { TokenHelperService } from './tokenHelper.service';


@NgModule({
    imports: [],
    exports: [],
    declarations: [],
    providers: [/* declare in `forRoot()` */],
})
export class ServicesModule {

    static forRoot(): ModuleWithProviders {
        return {
            ngModule: ServicesModule,
            providers: [
                ActiveScopeService,
                AuthService,
                CustomerService,
                StatistiqueService,
                TypeCustomerService,
                TypeWasteService,
                UserService,
                RewardService,
                CityService,
                AuthGuardService,
                TokenHelperService
            ]
        };
    }
}
