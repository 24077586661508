import { Component, OnInit, Input,Output,EventEmitter, OnChanges, Inject, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors, FormGroupDirective, NgForm, FormArray } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IResponse } from 'src/app/api/models/response.interface';
import { User } from 'src/app/api/resources/user_resource';
import { CountryCode } from 'src/app/utils/country_list/support/code_country';
import { IUserPayload} from 'src/app/services/payload/user.payload';
import { UserService } from 'src/app/services/user.service';
import { Alert, IAction, IRole, IUserAction, map2Obj, roles, SubmitAction } from 'src/app/utils/utils';
import { CountryListPick } from 'src/app/utils/country_list/country_list';
declare const $: any;
declare const swal: any;

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    
    const invalidCtrl = !!(control && control.invalid && control.parent.dirty);
    const invalidParent = !!(control && control.parent && control.parent.invalid && control.parent.dirty);

    return invalidCtrl || invalidParent;
  }
}

export interface Task {
  libele: string;
  name: string;
  checked: boolean;
  diseable: boolean;
  visibility: boolean
}

@Component({
  selector: 'sesa-add-agent',
  templateUrl: './add-agent.component.html',
  styleUrls: ['./add-agent.component.scss']
})
export class AddAgentComponent implements OnInit, OnChanges, AfterViewInit {
  userActions: IUserAction;
  addUserForm: FormGroup;
  matcher = new MyErrorStateMatcher();
  hide = true;

  currentUser: User;
  userSelected: User;

  taskRoles: Task[] = [
    { libele: "Agent",      name: "ROLE_AGENT",   diseable: false, checked: false, visibility: true },
    { libele: "Supervisor", name: "ROLE_SUPERV",  diseable: false, checked: false, visibility: true },
    { libele: "Administration", name: "ROLE_ADMIN", diseable: false, checked: false, visibility: true },
    { libele: "Super Administration", name: "ROLE_SUPER_ADMIN", diseable: false, checked: false, visibility: false }
  ]

  itemGenderTaskSelected: Task;

  // formError: any = {
  //   firstname: false,
  //   lastname: false,
  //   gender: false,
  //   gsm: false,
  //   address: false,
  //   city: false,
  //   email: false,
  //   plainPassword: false,
  //   plainRoles: false
  // }
  @Input() status: Alert;
  @Input() isSended: boolean = false;

  countries:CountryCode[] = []
  
  //user: User;

  constructor(
    private userService: UserService,
    public dialogRef: MatDialogRef<AddAgentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: SubmitAction, payload : User }
    ) { 
    this.status = {visibility: false,message: "",backgroudColor: ""}
    this.userActions = this.userService.getCurrentUserActions()
    this.currentUser = this.userService.getCurrentUser()
    this.userSelected = this.data.payload
    this.countries = (new CountryListPick({isDownIcon: true,isShowFlag: true,isShowTitle: true})).getCountries()
  }

  ngOnInit() {
    $('.selectpicker').selectpicker();
    $('.selectCountryCode').selectpicker();
    this._iniForm(this.data.action);
    
    
    if(this.data.action == SubmitAction.EDITING)
      this.updateCheckedRole(this.userSelected)
    if(this.data.action == SubmitAction.ADDING)
      this.taskRoles[0].checked = true

      
    console.log(this.countries[0].getCountryDialCode())
  }

  ngOnChanges(){  
    
  }

  ngAfterViewInit() {
    $('.selectCountryCode').selectpicker('refresh');
  }

  public _iniForm(action: SubmitAction) {
    this.addUserForm = new FormGroup({
      firstname: new FormControl(
          this.userSelected != null ? this.userSelected.firstname : null, [
          Validators.required, 
          Validators.pattern(  /[0-9a-zA-Z]/)
        ], null),
      lastname: new FormControl(
          this.userSelected != null ? this.userSelected.lastname : null, [
          Validators.required, 
          Validators.pattern(  /[0-9a-zA-Z]/)
        ], null),
      gender: new FormControl(
          this.userSelected != null ? this.userSelected.gender : null, [
          Validators.required, 
          Validators.pattern(  /[A-Z]/)
        ], null),
      gsm: new FormControl(
          {
            value: this.userSelected != null ? this.userSelected.gsm : null,
            disabled: action == SubmitAction.EDITING
          }, action == SubmitAction.EDITING ? [
          Validators.required, 
          Validators.maxLength(15),
          Validators.pattern(  /[0-9]{8}/),
          
        ] : null, null),
      address: new FormControl(
          this.userSelected != null ? this.userSelected.address : null, [
          Validators.required, 
          Validators.pattern(  /[0-9a-zA-Z]/)
        ], null),
      city: new FormControl(
          this.userSelected != null ? this.userSelected.city : null, [
          Validators.required, 
          Validators.pattern(  /[0-9a-zA-Z]/)
        ], null),
      email: new FormControl(
          {
            value:this.userSelected != null ? this.userSelected.email : null,
            disabled: action == SubmitAction.EDITING
          }, action == SubmitAction.EDITING ? [
          Validators.required, 
          Validators.email
        ] : null, null),
      plainPassword: new FormControl(
        {
          value: null,
          disabled: action == SubmitAction.EDITING
        }, action == SubmitAction.EDITING ? [
          Validators.required,
          Validators.minLength(8), 
          Validators.pattern(  /[0-9a-zA-Z]{6,}/)
      ] : null, null),
      confirmPassword: new FormControl(
        {
          value: null,
          disabled: action == SubmitAction.EDITING
        },action == SubmitAction.EDITING ? [
          Validators.required,
          Validators.minLength(8),
          Validators.pattern(  /[0-9a-zA-Z]{6,}/)
        ] : null,
        
      ),
      roles: new FormControl(null, null, null), //new FormArray([]) //
      //plainRoles: new FormControl(null, [Validators.required], null),
    },{ validators: action == SubmitAction.EDITING ? this.checkPasswords : null });

    //this.addUserForm.controls['gsm'].disable({onlySelf: true});;
  }

  updateCheckedRole(user: User):void  {
    
    if(user) {
      let roles:string[] = user.roles
      
      roles.forEach((e)=>{
        let index = this.taskRoles.findIndex((r)=> r.name === e)
        if(index > -1) {
          this.taskRoles[index].checked = true
          this.taskRoles[index].visibility = (e !== "ROLE_SUPER_ADMIN")
        }
        
      })
      this.taskRoles = this.taskRoles
      console.log("taskRoles", this.taskRoles)

    }

    // CREATION Array formGroup

    // const formArray = this.addUserForm.get('roles') as FormArray;
    // this.taskRoles.forEach(taskRole => {
    //   // generate control Group for each option and push to formArray
    //   if(taskRole.visibility) {
    //     formArray.push(new FormGroup({
    //       name: new FormControl(taskRole.libele),
    //       checked: new FormControl(taskRole.checked)
    //     }))
    //   }
    // })

  }

  checkPasswords: ValidatorFn = (group: AbstractControl):  ValidationErrors | null => { 
    let pass = group.get('plainPassword').value;
    let confirmPass = group.get('confirmPassword').value
    //console.log("pass === confirmPass",pass === confirmPass)
    return pass === confirmPass ? null : { notSame: true }
  }

  onSubmit(){
    let roles:string[] = this.getRoles();
    this.addUserForm.patchValue({"roles": roles})
    console.log(this.addUserForm.value)
    // if( this.itemGenderTaskSelected)
    //   this.addUserForm.patchValue({"gender": this.itemGenderTaskSelected.name})

    if(this.addUserForm.valid && roles.length > 0){
      swal({   
        title: "New user",   
        text: this.data.action == SubmitAction.ADDING ?  "You're about to add a user" : "You're about to modify" ,   
        type: "warning",   
        showCancelButton: true,   
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",   
      }).then((isConfirm)=> {
        if (isConfirm) {   
          this.dialogRef.close({action: this.data.action, payload: this.addUserForm.value})
        } 
      });
    }       
    else {
      console.log("Error form")
    }
  }

  setCheckedRoles(index: number, checked: boolean): void {
    this.taskRoles[index].checked = checked;
  }

  // setCheckedGender(item: Task): void {
  //   this.itemGenderTaskSelected = item
  // }

  private getRoles(): string[] {
    let roles: string[] = []
    this.taskRoles.forEach((t)=> {
      if(t.checked)
      roles.push(t.name)
    })
    return roles;
  }

  onReset() {
    this.addUserForm.reset()
  }
}
