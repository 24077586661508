import { CountryCode } from './support/code_country';
import { countries, ICountry } from './support/code_countrys';

export class CountryListPick{
  countries:ICountry [] = countries;
  countryList: CountryCode[];

    constructor(params:{
        isShowFlag: boolean,
        isDownIcon: boolean,
        isShowTitle: boolean 
      }){

      this.countryList = countries.map((s) => new CountryCode({
          name: s.name,
          code: s.code,
          dialCode: s.dial_code,
          flagUri: 'assets/flags/${s.code.toLowerCase()}.png',
          lang: s.lang
      }));

      this.countryList.sort((a,b) => a.getCountryName().localeCompare(b.getCountryName(), 'en'));
      
    }

    public getCountries(): CountryCode[] {
      return this.countryList;
    }
}