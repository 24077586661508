import { Injectable, Type } from '@angular/core';
import { ApiResources } from '../resources/api-resources';

/**
  *@author TINA K. Arouna <inbox@xcodevs.com>
  *
  * Class NgxApiResources
  * @package
  * Route("")
*/

@Injectable()
export class NgxApiResources {

  constructor(
    private _apiResources: ApiResources){ 
  }

  get loadResources(){
    return this._apiResources.getResources();
  }

}