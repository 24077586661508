

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Resource } from "ngx-resource-factory/resource/resource";
import { ResourceAction } from "ngx-resource-factory/resource/resource-action";
import { ResourceActionMethod } from "ngx-resource-factory/resource/resource-action-method";
import { ResourceConfiguration } from "ngx-resource-factory/resource/resource-configuration";
import { ResourceInstance } from "ngx-resource-factory/resource/resource-instance";
import { ResourceRegistry } from "ngx-resource-factory/resource/resource-registry";
import { environment } from "src/environments/environment";
import { Apiroutes } from "../routes/api_routes";


/**
  * Class CustomerResource
  * @author TINA K. Arouna <inbox@xcodevs.com>
  * @package
*/

export class City extends ResourceInstance {
    id: number;
    lib: string;
    countrycode: string;
    areas: string[];
  }
  
  @Injectable({
    providedIn: 'root'
  })
  @ResourceConfiguration({
      name: 'CityResource',
      url: environment.apiUrl, //+ 'crm/:pk/',//user/
      pkAttr: 'pk',
      instanceClass: City,
      stripTrailingSlashes: true,
  })
  export class CityResource extends Resource<City> {
  
    constructor(
      registry: ResourceRegistry,
      http: HttpClient) {
      super(registry, http);
    }
  
    @ResourceAction(Apiroutes.citiesUrl)
    _listCities: ResourceActionMethod<any, any, City>;
  }