import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl,FormBuilder, Validators } from '@angular/forms';
import { IAuthPayload } from 'src/app/services/payload/auth.payload';

@Component({
  selector: 'cpropre-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  signInForm: FormGroup;
  
  @Input() errorMessage: string;
  @Output() changeTemplate = new EventEmitter<boolean>()
  @Output() login = new EventEmitter<IAuthPayload>();

  constructor() { }

  ngOnInit() {
    this._iniForm();
  }

  _iniForm(){
    this.signInForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email], null),
      password: new FormControl(null, [Validators.required, Validators.pattern(  /[0-9a-zA-Z]{6,}/)], null),
    });
  }

  onSubmit(){
    
    if(this.signInForm.controls.email.status == "INVALID" || this.signInForm.controls.password.status == "INVALID")
      this.errorMessage = "Les champs sont obligatoires"
    else
      this.login.emit(this.signInForm.value);
  }

  onChangeTemplate(){
    this.changeTemplate.emit(true);
  }
}
