import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'cpropre-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  signUpForm: FormGroup;
  errorMessage: string
  @Output() changeTemplate = new EventEmitter<boolean>()
  constructor(private formBuilder: FormBuilder,
    //private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this._iniForm();
  }

  _iniForm(){
    this.signUpForm = this.formBuilder.group(
      {
        '_email': ["", [Validators.required, Validators.email]],
        '_pwd': ["", [Validators.required, Validators.pattern(  /[0-9a-zA-Z]{6,}/)]]
      }
    )
  }

  onSubmit(){
   
  }

  onChangeTemplate(){
    this.changeTemplate.emit(false);
  }

}
